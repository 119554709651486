import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { FaDiscord, FaGoogle, FaUsers, FaImage, FaUser, FaBuilding, FaPalette, FaUserFriends, FaCat, FaTwitter, FaSpinner } from 'react-icons/fa';
import { 
FaPaintBrush, FaCameraRetro, FaProjectDiagram 
} from 'react-icons/fa';

import { auth } from './firebase';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  onAuthStateChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut
} from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import YouTube from 'react-youtube';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { CgGhostCharacter } from "react-icons/cg";

import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { FaCheck } from 'react-icons/fa';



import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

/* ...other imports... */

// Import required icons
import { 
  // ... existing imports ...
  FaClock, FaLightbulb, FaCheckCircle
} from 'react-icons/fa';




const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #000;
    color: #fff;
    overflow-x: hidden; // Prevent horizontal scrolling
  }

  * {
    box-sizing: inherit;
  }
`;

const PageWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
  
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const Hero = styled.section`
  background: url('path-to-your-image.jpg') no-repeat center center/cover;
  background-color: rgba(0, 0, 0, 0.5); /* Fallback color */
  background-blend-mode: darken;
  width: 100%;
  padding: 100px 20px;
  color: #fff; /* Light text color for better contrast */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #111; /* Dark background for hero */
  @media (max-width: 768px) {
    padding: 60px 20px;
  }
`;

const HeroTitle = styled.h1`
  font-size: 4em;
  margin: 0;
  font-weight: bold;
  margin-left: 50px;
  margin-right: 50px;

  @media (max-width: 768px) {
    font-size: 2.5em;
    margin-top: 20px;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 2em; /* Increased font size */
  margin: 20px 0;
  font-weight: bold; /* Added bold font weight */
  margin-left: 50px;
  margin-right: 50px;

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 18px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #45a049;
  }
`;

const EmailInput = styled.input`
  padding: 15px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  width: 300px;
  margin-left: 10px;
  max-width: 100%;

`;

const SignUpButton = styled(Button)`
  padding: 15px 30px;
  background-color: #ff5722;
  &:hover {
    background-color: #e64a19;
  }
`;

const HeroForm = styled.div`
  display: flex;
  
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  max-width: 300px; // Reduced from 400px
  background-color: rgba(255, 255, 255, 0.1); // Semi-transparent background
  padding: 30px 40px; // 30px for top and bottom, 40px for left and right
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 20px;
  }
`;

const HeroEmailInput = styled(EmailInput)`
  margin-bottom: 15px;
  width: 100%;
  max-width: 275px; // Increased from 240px
  background-color: rgba(255, 255, 255, 0.9); // More opaque background for input
  color: #000; // Dark text for better contrast
  text-align: center; // Center the text inside the input

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const HeroSignUpButton = styled(SignUpButton)`
  width: 100%;
  max-width: 260px; // Match the new max-width of HeroEmailInput
  margin-bottom: 15px;
  padding: 10px 20px;
  font-size: 16px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const GoogleSignInButton = styled(Button)`
  background-color: #4285F4;
  width: 100%;
  max-width: 260px;
  padding: 10px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #357ae8;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const GoogleIcon = styled(FaGoogle)`
  margin-right: 10px;
`;

const Header = styled.header`
  background-color: transparent;
  color: white;
  padding: 20px 0;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
`;

const Logo = styled.img`
  height: 50px;
`;

const SubTitle = styled.h2`
  color: #fff; /* Changed font color to white */
  font-size: 2em;
  margin: 20px 0;
`;

const Content = styled.div`
  margin: 40px 0;

  padding: 20px;
  background: #111; /* Dark background for content */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 78%;
  color: #fff; /* Light text color for better contrast */

  // handle mobile
  @media (max-width: 768px) {
    max-width: 100%;
  }

  `;

const FloatingSignUp = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(17, 17, 17, 0.9);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const EmailForm = styled.div`
  display: flex;
  align-items: center;
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
`;

const Footer2 = styled.footer`
  background-color: #111;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const DiscordLink = styled.a`
  color: #1DA1F2;
  font-size: 24px;
  transition: color 0.3s ease;
  &:hover {
    color: #0c85d0;
  }
`;

const DashboardButton = styled.button`
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
  width: 100%;
  max-width: 260px;
  &:hover {
    background-color: #45a049;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const LoadingIcon = styled(FaSpinner)`
  color: white;
  font-size: 48px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingText = styled.p`
  color: white;
  font-size: 24px;
  margin-top: 20px;
`;

const HeaderButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const LogoutButton = styled.button`
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d32f2f;
  }
`;

const UseCaseDescription = styled.p`
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #ddd;
  line-height: 1.6;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;


const InfoBanner = styled.div`
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  margin-top: 10px;
  border-radius: 5px;
  margin-left: 50px;
  margin-right: 50px;

  // mobile
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const GuideWorkflow = styled.div`
  margin-top: 60px;
  text-align: center;
  background-color: #1a1a1a;
  padding: 40px 20px; // Added horizontal padding
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const WorkflowTitle = styled.h3`
  font-size: 32px;
  margin-bottom: 40px;
  color: white;
  letter-spacing: 2px;
`;

const WorkflowSteps = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
`;

const WorkflowStep = styled.div`
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  background-color: #222;
  padding: 30px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px; // Added margin-bottom for better spacing on mobile

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.3);
  }
`;

const StepNumber = styled.span`
  font-size: 48px;
  font-weight: bold;
  color: white;
  display: block;
  margin-bottom: 15px;
`;

const StepText = styled.span`
  font-size: 18px;
  line-height: 1.5;
  color: #ddd;
`;

const SocialProof = styled.div`
  background-color: #1a1a1a;
  padding: 40px 20px; // Added horizontal padding
  border-radius: 15px;
  margin-top: 60px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const SocialProofTitle = styled.h3`
  font-size: 32px;
  color: #fff;
  margin-bottom: 30px;
`;

const SocialProofGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap; // Allow items to wrap on smaller screens
`;

/* ...existing styled components... */

const Tooltip = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &::after {
    content: attr(data-tooltip);
    white-space: pre-wrap;
    position: absolute;
    bottom: 100%; /* Position the tooltip above the text */
    left: 50%;
    transform: translateX(-50%);
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 10px;
    width: 250px;
    border-radius: 6px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
  }

  &:hover::after {
    visibility: visible;
    opacity: 1;
  }
`;

/* ...rest of styled components... */

const SocialProofItem = styled.div`
  background-color: #222;
  padding: 30px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  flex: 0 1 250px;
  margin-bottom: 20px; // Added margin-bottom for better spacing on mobile

  &:hover {
    transform: translateY(-5px);
  }
`;

const SocialProofIcon = styled.div`
  font-size: 48px;
  color: #4CAF50;
  margin-bottom: 15px;
`;

const SocialProofNumber = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
`;

const SocialProofText = styled.p`
  font-size: 18px;
  color: #ddd;
`;

const UseCaseSection = styled.div`
  background-color: #1a1a1a;
  padding: 60px 20px;
  border-radius: 15px;
  margin-top: 60px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const UseCaseTitle = styled.h3`
  font-size: 32px;
  color: #fff;
  margin-bottom: 30px;
`;

const UseCaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
`;

const UseCaseItem = styled.div`
  background-color: #222;
  padding: 30px;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.3);
  }

  ${props => props.selected && `
    background-color: #4CAF50;
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.5);

    ${UseCaseIcon} {
      color: #fff;
    }

    ${UseCaseText} {
      color: #fff;
    }
  `}
`;

const UseCaseIcon = styled.div`
  font-size: 48px;
  color: #4CAF50;
  margin-bottom: 15px;
`;

const UseCaseText = styled.p`
  font-size: 18px;
  color: #ddd;
`;

const VideoGuidance = styled.h3`
  font-size: 24px;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const YouTubeContainer = styled.div`
  position: relative;
  padding-bottom: ${props => props.isMobile ? '177.77%' : '56.25%'}; // 9:16 for mobile, 16:9 for desktop
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
`;

const StyledYouTube = styled(YouTube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const MobileNotification = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 1000;
  max-width: 80%;
`;

/* Existing styled components... */

/* Add styled components from Checkout.js necessary for the pricing section */
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const ToggleLabel = styled.span`
  margin: 0 10px;
  color: ${props => props.isActive ? '#fff' : '#888'};
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
    background-color: #2196F3;
  }

  &:checked + ${ToggleSlider}:before {
    transform: translateX(26px);
  }
`;




const EstimationContainer = styled.div`
  margin-bottom: 30px;
  text-align: left;
`;

const EstimationDetail = styled.div`
  color: #ecf0f1;
  font-weight: bold;
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(FaCheck)`
  color: #2ecc71;
  margin-right: 5px;
  min-width: 16px;
  min-height: 16px;
`;

const CenteredHeading = styled.h1`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const SubHeading = styled.p`
  text-align: center;
  font-size: 1.1em;
  color: #999;
  margin-bottom: 30px;
  margin-left: 40px;
  margin-right: 40px;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const MostPopularBadge = styled.span`
  background-color: #f1c40f;
  color: #2c3e50;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8em;
  position: absolute;
  top: -10px;
  left: -10px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 0.7em;
  }
`;


const PricingSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 40px auto;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    gap: 30px;
  }
`;

const PricingBox = styled.div`
  background-color: #1e2a38;
  border-radius: 15px;
  padding: 30px;
  width: 280px;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }

  ${props => props.popular && `
    background-color: #2c3e50;
    border: 2px solid #3498db;
  `}

  @media (max-width: 768px) {
    width: 100%;
    max-width: 280px;
  }
`;

const PackageTitle = styled.h3`
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #fff;
`;

const CreditsDisplay = styled.div`
  font-size: 2.2em;
  font-weight: bold;
  color: #3498db;
  margin-bottom: 15px;
`;

const PriceContainer = styled.div`
  margin: 20px 0;
`;

const CurrentPrice = styled.span`
  font-size: 2.2em;
  font-weight: bold;
  color: #2ecc71;
`;

const PerPeriod = styled.span`
  font-size: 0.5em;
  color: #bdc3c7;
  vertical-align: middle;
`;

const PricingBoxButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1em;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 20px;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
`;

const FeatureItem = styled.li`
  color: #ecf0f1;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &::before {
    content: "✓";
    color: #2ecc71;
    margin-right: 10px;
    font-weight: bold;
  }
`;

const PopularBadge = styled.span`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #e74c3c;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8em;
  font-weight: bold;
`;


const FAQSection = styled.div`
  margin-top: 60px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  margin-bottom: 60px;
`;

const FAQTitle = styled.h2`
  font-size: 2em;
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
`;

const FAQItem = styled.div`
  background-color: #2c3e50;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 6px 12px rgba(52, 152, 219, 0.2);
  }
`;

const FAQQuestion = styled.div`
  color: #ecf0f1;
  background-color: #34495e;
  padding: 20px;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.1em;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2c3e50;
  }
`;

const FAQAnswer = styled.div`
  color: #ecf0f1;
  text-align: left;
  background-color: #2c3e50;
  max-height: ${props => (props.isOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease;
  padding: ${props => (props.isOpen ? '20px' : '0 20px')};
  font-size: 1em;
  line-height: 1.6;
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transition: background-color 0.3s ease;

  ${FAQQuestion}:hover & {
    background-color: #2980b9;
  }
`;



/* Stripe initialization */
const stripePromise = loadStripe('pk_live_51PufGf03eI7eG1Nf5ck8ESBKiBpIz7pVD5EEWWeLNTHUJfpVj63DsEVpfgIUfgGjLzRQQariv90ejHxnWn4Fvitw00gW5wfYVM');

// Styled Components for Benefits Section
const BenefitsSection = styled.div`
  background-color: #1a1a1a;
  padding: 60px 20px;
  border-radius: 15px;
  margin-top: 60px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const BenefitsTitle = styled.h2`
  font-size: 32px;
  color: #fff;
  margin-bottom: 30px;
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
`;

const BenefitItem = styled.div`
  background-color: #222;
  padding: 30px;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: default;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.3);
  }
`;

const BenefitIcon = styled.div`
  font-size: 48px;
  color: #4CAF50;
  margin-bottom: 15px;
`;

const BenefitTitle = styled.h3`
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
`;

const BenefitDescription = styled.p`
  font-size: 16px;
  color: #ddd;
  line-height: 1.5;
`;

const LandingPage = () => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [referrerCode, setReferrerCode] = useState(null);

  const [selectedUseCase, setSelectedUseCase] = useState('Generate your own art with AI-driven variations that maintain your unique style');

  const useCases = [
    { icon: <FaPaintBrush />, text: "Generate your own art with AI-driven variations that maintain your unique style" },
    { icon: <FaCameraRetro />, text: "Create custom AI portraits and images from your own photos" },
    { icon: <FaUser />, text: "Generate yourself in different scenarios and outfits from your own photos" },
    { icon: <FaProjectDiagram />, text: "Design custom brand visuals and assets using your existing artwork" },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [showMobileNotification, setShowMobileNotification] = useState(false);

  const [isGoogleSignInLoading, setIsGoogleSignInLoading] = useState(false);




  /* State variables for pricing section */
  const [openFAQs, setOpenFAQs] = useState({});
  const [isYearly, setIsYearly] = useState(true);
  const [userEmail, setUserEmail] = useState(null);
  const [userId, setUserId] = useState(null);
  const [creditsSpent, setCreditsSpent] = useState(0);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);



 /* Subscriptions object */
 const subscriptions = {
  freeTrial: {
    name: 'Free Trial',
    monthlyPrice: '$0',
    yearlyPrice: '$0',
    credits: '5',
    estimatedPictures: 33,
    estimatedModels: 1,
    fixedYearlyCost: 0,
    monthlyStripePriceId: 'price_1Q1Uu303eI7eG1NfZNBdsS7Q',
    yearlyStripePriceId: 'price_1Q1Uu303eI7eG1NfZNBdsS7Q',
  },
  basic: {
    name: 'Basic',
    monthlyPrice: '$20',
    yearlyPrice: '$180',
    credits: '240',
    estimatedPictures: 1600,
    estimatedModels: 80,
    fixedYearlyCost: 0,
    monthlyStripePriceId: 'price_1Q4jme03eI7eG1NfECAA6XIz',
    yearlyStripePriceId: 'price_1Q4jme03eI7eG1NfF99lgCZb',
  },
  pro: {
    name: 'Pro',
    monthlyPrice: '$60',
    yearlyPrice: '$540',
    credits: '720',
    estimatedPictures: 4800,
    estimatedModels: 240,
    monthlyStripePriceId: 'price_1Q4jnq03eI7eG1NfTPKU9IbO',
    yearlyStripePriceId: 'price_1Q4jnq03eI7eG1NfkvRvNUmz',
    isMostPopular: true,
  },
  enterprise: {
    name: 'Enterprise',
    monthlyPrice: '$180',
    yearlyPrice: '$1620',
    credits: '2160',
    estimatedPictures: 14400,
    estimatedModels: 720,
    monthlyStripePriceId: 'price_1Q4jl103eI7eG1NfEKrhiCR9',
    yearlyStripePriceId: 'price_1Q4jl103eI7eG1NfqFfgWaAd',
  },
};

/* Helper functions */
const formatNumber = (num) => {
  return Number(num).toLocaleString();
};

const getMonthlyPrice = (yearlyPrice) => {
  return `$${(parseFloat(yearlyPrice.replace('$', '')) / 12).toFixed(0)}`;
};

const getMonthlyCredits = (yearlyCredits) => {
  return Math.round(parseInt(yearlyCredits) / 12);
};

const getCostPerImage = (price, estimatedPictures, fixedYearlyCost) => {
  if (isYearly && fixedYearlyCost) {
    return fixedYearlyCost.toFixed(3);
  }
  const monthlyPrice = isYearly ? parseFloat(price.replace('$', '')) / 12 : parseFloat(price.replace('$', ''));
  const monthlyPictures = estimatedPictures / 12;
  return (monthlyPrice / monthlyPictures).toFixed(3);
};

const toggleFAQ = (index) => {
  setOpenFAQs(prev => ({
    ...prev,
    [index]: !prev[index],
  }));
};

/* Fetch user data and credits spent */
useEffect(() => {
  const fetchUserData = async (user) => {
    setUserEmail(user.email);
    setUserId(user.uid);
    // await fetchCreditsSpent(user.uid);
  };

  // Capture referrerCode from URL parameters
  const params = new URLSearchParams(window.location.search);
  const referrer = params.get('r');
  if (referrer) {
    setReferrerCode(referrer);
    // Optionally store in localStorage
    // console.log("Referrer code:" +referrer)
    window.localStorage.setItem('referrerCode', referrer);
  }

  const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      setUser(currentUser);
      fetchUserData(currentUser);
    } else {
      setUser(null);
      setUserEmail(null);
      setUserId(null);
      setCreditsSpent(0);
    }
  });

  return () => unsubscribe();
}, []);

const fetchCreditsSpent = async (uid) => {
  try {
    const response = await axios.get(`https://fastapi-app-ok2y6f2snq-uc.a.run.app/credits?user_id=${uid}`);
    if (response.data && typeof response.data.credits_spent === 'number') {
      setCreditsSpent(response.data.credits_spent);
      setSubscriptionType(response.data.subscription_type);
      setSubscriptionStatus(response.data.subscription_status);
    } else {
      setCreditsSpent(0);
    }
  } catch (error) {
    console.error('Error fetching credits spent:', error);
    setCreditsSpent(0);
  }
};



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const useCaseImages = {
    "Generate your own art with AI-driven variations that maintain your unique style": isMobile ? "/show2mobile.png" : "/show2.png",
    "Create custom AI portraits and images from your own photos": isMobile ? "/show2mobile.png" : "/show2.png",
    "Generate AI art featuring your friends and family in your artistic style": isMobile ? "/show2mobile.png" : "/show2.png",
    "Design custom brand visuals and assets using your existing artwork": isMobile ? "/show2mobile.png" : "/show2.png",
  };

  const useCaseVideos = {
    "Generate your own art with AI-driven variations that maintain your unique style": null,
    "Create custom AI portraits and images from your own photos": null,
    "Generate AI art featuring your friends and family in your artistic style": null,
    "Design custom brand visuals and assets using your existing artwork": null,
  };

  const handleUseCaseClick = (text) => {
    setSelectedUseCase(text);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      // console.log("Auth state changed. Current user:", currentUser);
      setUser(currentUser);
      // if (currentUser) {
      //   // console.log("User is signed in. Fetching user data from Firestore...");
      //   const db = getFirestore();
      //   const userDocRef = doc(db, 'customers', currentUser.uid);
        
      //   try {
      //     let attempts = 0;
      //     const maxAttempts = 10;
      //     const checkDocument = async () => {
      //       const userDocSnap = await getDoc(userDocRef);
      //       if (userDocSnap.exists()) {
      //         const userData = userDocSnap.data();
      //         // console.log("User data fetched:", userData);
      //         if ((userData.credits === undefined && userData.credits_spent === undefined) || (userData.credits === 0 && userData.credits_spent === 0)) {
      //           // console.log("Credits and credits_spent are undefined or zero. Redirecting to checkout...");
      //           navigate('/checkout');
      //         } else {
      //           // console.log("Credits or credits_spent are defined:", userData.credits, userData.credits_spent);
      //           // navigate('/app');
      //         }
      //       } else {
      //         // console.log("User document does not exist in Firestore. Attempt:", attempts + 1);
      //         if (attempts < maxAttempts) {
      //           attempts++;
      //           setTimeout(checkDocument, 1000); // Wait 1 second before trying again
      //         } else {
      //           // console.log("Max attempts reached. User document not found.");
      //           navigate('/checkout'); // Redirect to checkout as a fallback
      //         }
      //       }
      //     };
          
      //     checkDocument();
      //   } catch (error) {
      //     console.error("Error fetching user data:", error);
      //   }
      // } else {
      //   // console.log("No user is signed in.");
      // }
    });

    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      setIsLoading(true); // Start loading
      signInWithEmailLink(auth, email, window.location.href)
      .then(async (result) => {
        window.localStorage.removeItem('emailForSignIn');
        // Fetch user data from Firestore
        const db = getFirestore();
        const userDocRef = doc(db, 'customers', result.user.uid);
        
        try {
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setTimeout(() => {
              setIsLoading(false);
              if ((userData.credits === undefined && userData.credits_spent === undefined) || 
                  (userData.credits === 0 && userData.credits_spent === 0)) {
                navigate('/checkout');
              } else {
                // navigate('/app');
              }
            }, 7000);
          } else {
            // console.log("User document does not exist in Firestore.");
            setIsLoading(false);
            // You might want to handle this case, perhaps by creating a new document or showing an error
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setIsLoading(false);
          // Handle error (e.g., show error message to user)
        }
      })
      .catch((error) => {
        console.error("Error signing in with email link:", error);
        setIsLoading(false);
        // Handle error (e.g., show error message to user)
      });
    }

    return () => unsubscribe();
  }, [navigate]);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setIsGoogleSignInLoading(true);
      const result = await signInWithPopup(auth, provider);

      // Get the user's UID, email, and ID token
      const userId = result.user.uid;
      const userEmail = result.user.email;
      const idToken = await result.user.getIdToken();


      const referrerCode = window.localStorage.getItem('referrerCode');

      // Call the backend function to handle IP verification
      const response = await fetch('https://handle-user-sign-in-1073944580948.us-central1.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Ensure this header is set
        },
        body: JSON.stringify({
          userId: userId,
          userEmail: userEmail,
          idToken: idToken,
          referrerCode: referrerCode

        }),
        mode: 'cors', // Add this line to enable CORS
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("Response data:", data);

      if (data.proceedToApp) {
        // console.log("User can access the app directly");
        // User can access the app directly
        navigate('/app');
      } else if (data.requirePhoneVerification) {
        // console.log("User requires phone verification");
        // Navigate to onboarding or phone verification flow
        navigate('/checkout'); // Replace with your actual onboarding route
      } else if (data.proceedToCheckout) {
        // console.log("User should proceed to checkout");
        navigate('/checkout'); // Replace with your actual checkout route
      } else {
        // console.log("Unexpected response from server");
        // Handle other cases if necessary
        alert('Unexpected response from server. Please try again.');
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
      // Display error message to the user
      // console.log(`Error signing in with Google: ${error.message}`);
    } finally {
      setIsGoogleSignInLoading(false);
    }
  };

  const goToDashboard = () => {
    // console.log("Attempting to navigate to dashboard");
    navigate('/app');
  };

  useEffect(() => {
    // console.log("Current user state:", user);
  }, [user]);

  const sendSignInLink = async (e) => {
    e.preventDefault();
    const actionCodeSettings = {
      url: window.location.href, // Use the current URL
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      if (window.innerWidth <= 768) {
        setShowMobileNotification(true);
        setTimeout(() => setShowMobileNotification(false), 7000); // Hide after 5 seconds
      } else {
        alert('Check your inbox and sign in via the link in the email to start creating custom AI images');
      }
    } catch (error) {
      console.error("Error sending sign-in link:", error);
      alert('Error sending sign-in link. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // console.log("User signed out successfully");
      // You can add additional logic here, such as showing a message to the user
    } catch (error) {
      console.error("Error signing out:", error);
      // Handle errors here, such as displaying an error message to the user
    }
  };

  const ProblemImage = styled(ResponsiveImage)`
    margin-top: 40px;
    margin-bottom: 20px;
  `;

  // Define the benefits data
  const benefits = [
    {
      icon: <FaClock />,
      title: "Save Time",
      description: "Reduce hours of drawing and editing to mere seconds",
    },
    {
      icon: <FaLightbulb />,
      title: "Enhance Creativity",
      description: "Experiment with new ideas quickly and efficiently",
    },
    {
      icon: <FaUserFriends />,
      title: "User-Friendly Interface",
      description: "Designed for artists of all levels, from beginners to professionals",
    },
    {
      icon: <FaCheckCircle />,
      title: "High-Quality Results",
      description: "Generate images that maintain the integrity and style of your original artwork with 4K resolution.",
    },
  ];

  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        {(isLoading || isGoogleSignInLoading) && (
          <LoadingOverlay>
            <LoadingIcon />
            <LoadingText>
              {isLoading ? "Setting up your account..." : "Signing in with Google..."}
            </LoadingText>
          </LoadingOverlay>
        )}
        <MainContent>
          <Header>
            <Logo src="/logo.png" alt="PicTrain Logo" />
            <HeaderButtons>
              {user ? (
                <>
                  <DashboardButton onClick={goToDashboard}>Go to App</DashboardButton>
                  <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
                </>
              ) : (
                <GoogleSignInButton onClick={signInWithGoogle}>
                  <GoogleIcon /> Sign in with Google
                </GoogleSignInButton>
              )}
            </HeaderButtons>
          </Header>
          <Hero>
          <HeroTitle>Generate custom AI art with simple text prompts</HeroTitle>
          <HeroSubtitle>Upload your own art or photos to build a custom AI image generator. Use simple text prompts to instantly generate new images that match your vision.

</HeroSubtitle> 
          <InfoBanner>No technical skills required — set up your custom AI image generator in less than 2 minutes.

</InfoBanner>
            <HeroForm>
              <HeroEmailInput 
                type="email" 
                placeholder="Enter your email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <HeroSignUpButton onClick={sendSignInLink}>Start Generating Custom AI Images</HeroSignUpButton>
              {!user ? (
                <GoogleSignInButton onClick={signInWithGoogle}>
                  <GoogleIcon /> Continue with Google
                </GoogleSignInButton>
              ) : (
                <DashboardButton onClick={goToDashboard}>
                  Go to App
                </DashboardButton>
              )}
            </HeroForm>
          </Hero>
          
          <Container>
            <Content>
              <SubTitle>Tired of generic AI art that doesn't preserve your unique art style?</SubTitle>
              <ProblemImage 
                src={isMobile ? "/problemmobile.png" : "/problem.png"} 
                alt="Problem illustration" 
              />
            </Content>
            <Content>
            <SubTitle>With Pictrain, easily generate consistent custom AI images preserving your unique style</SubTitle>
            {useCaseImages[selectedUseCase] && (
      <ResponsiveImage 
        src={useCaseImages[selectedUseCase]} 
        alt={`Illustration for ${selectedUseCase}`} 
      />
    )}
    {useCaseVideos[selectedUseCase] && (
      <>
        <VideoGuidance>{selectedUseCase} video demonstration</VideoGuidance>
        <YouTubeContainer isMobile={isMobile}>
          <StyledYouTube
            videoId={useCaseVideos[selectedUseCase]}
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                autoplay: 0,
                rel: 0,  // This prevents showing related videos
                modestbranding: 1,  // This removes most YouTube branding
                controls: 1,
                ...(isMobile ? { orientation: 'portrait' } : {}),
              },
            }}
          />
        </YouTubeContainer>
      </>
    )}
                          <InfoBanner>
            Works with your own personal 2D/3D art styles, 2D/3D characters/assets, photorrealistic pictures, custom brands, etc.
            </InfoBanner>
            <BenefitsSection>
      <BenefitsTitle>Benefits of using Pictrain</BenefitsTitle>
      <BenefitsGrid>
        {benefits.map((benefit, index) => (
          <BenefitItem key={index}>
            <BenefitIcon>{benefit.icon}</BenefitIcon>
            <BenefitTitle>{benefit.title}</BenefitTitle>
            <BenefitDescription>{benefit.description}</BenefitDescription>
          </BenefitItem>
        ))}
      </BenefitsGrid>
    </BenefitsSection>



              <UseCaseSection>
                <UseCaseTitle>Unlock countless possibilities</UseCaseTitle>
                <UseCaseGrid>
                  {useCases.map((useCase, index) => (
                    <UseCaseItem 
                      key={index} 
                      onClick={() => handleUseCaseClick(useCase.text)}
                      selected={selectedUseCase === useCase.text}
                    >
                      <UseCaseIcon>{useCase.icon}</UseCaseIcon>
                      <UseCaseText>{useCase.text}</UseCaseText>
                    </UseCaseItem>
                  ))}
                </UseCaseGrid>
              </UseCaseSection>
              

              {selectedUseCase && (useCaseImages[selectedUseCase] || useCaseVideos[selectedUseCase]) && (
  <>


  </>
)}

              <GuideWorkflow>
                <WorkflowTitle>Get started in three easy steps</WorkflowTitle>
                <WorkflowSteps>
                  <WorkflowStep>
                    <StepNumber>1</StepNumber>
                    <StepText>Create an account or sign in with Google</StepText>
                  </WorkflowStep>
                  <WorkflowStep>
                    <StepNumber>2</StepNumber>
                    <StepText>Upload 5 to 15 images of your art</StepText>
                  </WorkflowStep>
                  <WorkflowStep>
                    <StepNumber>3</StepNumber>
                    <StepText>Generate the images you want using simple text prompts</StepText>
                  </WorkflowStep>
                </WorkflowSteps>
              </GuideWorkflow>
              {/* <SocialProof>
                <SocialProofTitle>Join our growing community</SocialProofTitle>
                <SocialProofGrid>
                  <SocialProofItem>
                    <SocialProofIcon><FaUsers /></SocialProofIcon>
                    <SocialProofNumber>1,000+</SocialProofNumber>
                    <SocialProofText>Creators generating custom AI images</SocialProofText>
                  </SocialProofItem>
                  <SocialProofItem>
                    <SocialProofIcon><FaImage /></SocialProofIcon>
                    <SocialProofNumber>20,000+</SocialProofNumber>
                    <SocialProofText>Custom AI images generated</SocialProofText>
                  </SocialProofItem>
                </SocialProofGrid>
              </SocialProof> */}
{/* 

              <CenteredHeading>Choose your plan</CenteredHeading>
              <SubHeading>Subscribe to a plan and get credits to generate AI custom images.</SubHeading>
    
              <ToggleContainer>
                <ToggleLabel isActive={!isYearly}>Monthly</ToggleLabel>
                <ToggleSwitch>
                  <ToggleInput type="checkbox" checked={isYearly} onChange={() => setIsYearly(!isYearly)} />
                  <ToggleSlider />
                </ToggleSwitch>
                <ToggleLabel isActive={isYearly}>Yearly (Save 25%)</ToggleLabel>
              </ToggleContainer> */}
    
              {/* <PricingSection>
  {Object.entries(subscriptions)
    .filter(([key]) => key !== 'freeTrial' || creditsSpent < 0.3)
    .map(([key, value]) => (
      <PricingBox key={key} popular={value.isMostPopular}>
        {value.isMostPopular && <PopularBadge>Most Popular</PopularBadge>}
        <PackageTitle>{value.name}</PackageTitle>
        <CreditsDisplay>
          {isYearly 
            ? `${value.credits} credits` 
            : key === 'freeTrial' 
              ? `${value.credits} credits` 
              : `${getMonthlyCredits(value.credits)} credits`}
        </CreditsDisplay>
        <PriceContainer>
          <CurrentPrice>
            {isYearly ? getMonthlyPrice(value.yearlyPrice) : value.monthlyPrice}
            <PerPeriod> / month</PerPeriod>
          </CurrentPrice>
        </PriceContainer>
        <FeatureList>
          <FeatureItem>
            Generate {formatNumber(isYearly ? value.estimatedPictures : Math.round(value.estimatedPictures / 12))} custom images
          </FeatureItem>
          <FeatureItem>
            Create {isYearly ? value.estimatedModels : Math.round(value.estimatedModels / 12)} custom image generators
          </FeatureItem>
          <FeatureItem>Full access to all features</FeatureItem>
          {key !== 'freeTrial' && <FeatureItem>Commercial use included</FeatureItem>}
        </FeatureList>
        <PricingBoxButton
          disabled={isLoading}
          onClick={() => handleSubscription(key)}
        >
          {isLoading ? 'Processing...' : key === 'freeTrial' ? 'Start Free Trial' : 'Get Started'}
        </PricingBoxButton>
      </PricingBox>
    ))}
</PricingSection> */}


{/*           
<FAQSection>
  <FAQTitle>FAQ</FAQTitle>
  {faqItems.map((item, index) => (
    <FAQItem key={index}>
      <FAQQuestion onClick={() => toggleFAQ(index)}>
        {item.question}
        <IconWrapper>
          {openFAQs[index] ? <FaChevronUp size={14} color="#fff" /> : <FaChevronDown size={14} color="#fff" />}
        </IconWrapper>
      </FAQQuestion>
      <FAQAnswer isOpen={openFAQs[index]}>
        {item.answer}
      </FAQAnswer>
    </FAQItem>
  ))}
</FAQSection> */}


              <Footer2>
                <FooterLink href="/terms-of-service">ToS and Privacy Policy</FooterLink>
                <br></br> <br></br><br></br>
                {/* <DiscordLink href="https://discord.gg/NH7URS9g" target="_blank" rel="noopener noreferrer">
                <FaDiscord />
                </DiscordLink>
                */}
              </Footer2>
            </Content>
          </Container>
        </MainContent>
        
        {showMobileNotification && (
          <MobileNotification>
            Check your inbox and sign in with the link in the email to start creating custom AI images!
          </MobileNotification>
        )}
        
      </PageWrapper>
    </>
  );
};

export default LandingPage;

