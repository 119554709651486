import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import imageCompression from 'browser-image-compression';
import { FaBrush, FaSort } from 'react-icons/fa';
import { FaDownload, FaCopy, FaQuestionCircle, FaChevronDown, FaChevronUp, FaEdit, FaCheck, FaTimes, FaSearch, FaTimes as FaClose } from 'react-icons/fa';
import { FaDiscord } from 'react-icons/fa';
import {FaBars, FaCog} from 'react-icons/fa';
// Import Firebase
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { signOut } from 'firebase/auth'; // Add this line
import HelpForm from './HelpForm'; // Create this new component
import defaultGeneratorImage from './defaultgenerator.png';
import loadingGeneratorImage from './defaultgenerator.png'; // Add this import
import {  FaSignOutAlt, FaUserCircle } from 'react-icons/fa';
import { FaUpload } from "react-icons/fa";
import { IoIosCreate } from "react-icons/io";
import ImageEditor from './ImageEditor'; // New component for drawing masks

import { IoIosAdd } from "react-icons/io";
import { FaHome } from "react-icons/fa";
import { RiAiGenerate } from "react-icons/ri";

import { FaTrash } from 'react-icons/fa'; // If not already imported
import { FaPaintBrush } from 'react-icons/fa';

import { 
   FaImage, FaGem, FaDollarSign
} from 'react-icons/fa';



// Add these styled components for the Edit icon
const EditIconWrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  color: #ffffff;  /* White icon */
  background-color: rgba(0, 0, 0, 0.5);  /* Semi-transparent black background */
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
`;

const EditIcon = styled(FaEdit)`
  color: #ffffff;
`;


const Button = styled.button`
  padding: 10px 15px;
  margin-right: 10px;
  background-color: #333333; /* Dark gray background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #444444; /* Slightly lighter on hover */
  }
`;
const Input = styled.input`
  width: 99%;
  padding: 10px;
  border: 1px solid #555555; /* Dark gray border */
  border-radius: 4px;
  background-color: #222222; /* Dark gray background */
  color: #cccccc; /* Light gray text */

  &::placeholder {
    color: #c2c2c2; /* Medium gray placeholder */
  }
`;


const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #999999;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  &:hover {
    color: #e74c3c; /* Red color on hover */
  }
`;


const ReferralSection = styled.div`
margin-top: 30px;
background-color: #000000; /* Black background */
padding: 20px;
border-radius: 8px;
color: #cccccc; /* Light gray text */
`;

const ReferralHeading = styled.h3`
margin-top: 0;
color: #ffffff; /* White text */
`;

const ReferralText = styled.p`
margin: 10px 0;
color: #cccccc; /* Light gray text */
`;

const ReferralLinkContainer = styled.div`
display: flex;
align-items: center;
margin-top: 10px;
flex-wrap: wrap;
`;

const ReferralInput = styled.input`
flex: 1;
padding: 10px;
font-size: 14px;
border: 1px solid #555555; /* Dark gray border */
border-radius: 4px;
margin-right: 10px;
background-color: #222222; /* Dark gray background */
color: #ffffff; /* White text */

/* Full width on mobile */
@media (max-width: 600px) {
  margin-bottom: 10px;
  margin-right: 0;
  width: 100%;
}
`;

const CopyReferralButton = styled.button`
background-color: #333333; /* Dark gray background */
color: #ffffff; /* White text */
border: none;
padding: 10px 15px;
font-size: 14px;
border-radius: 4px;
cursor: pointer;
display: flex;
align-items: center;
  
/* Full width on mobile */
@media (max-width: 600px) {
  width: 100%;
  justify-content: center;
}

&:hover {
  background-color: #444444; /* Slightly lighter on hover */
}

svg {
  margin-right: 5px;
}
`;

// Styled component for a single generated image
const GeneratedImageSingle = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);  /* Subtle shadow */
  display: block;
`;
const DownloadOverlay = styled.div`
position: absolute;
bottom: 10px;
right: 10px;
background-color: rgba(0, 0, 0, 0.5);
border-radius: 50%;
padding: 8px;
cursor: pointer;
transition: opacity 0.3s ease;
opacity: 0.7;

&:hover {
  opacity: 1;
}
`;

const DownloadIcon = styled(FaDownload)`
color: #ffffff; /* White icon */
font-size: 20px;
`;

const PlaceholderBox = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
background-color: #000000; /* Black background */
`;


// Styled component for the grid of generated images
const GeneratedImagesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  /* On mobile devices, stack images vertically */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
// Wrapper for each generated image
const GeneratedImageWrapper = styled.div`
  position: relative;
  display: inline-block;

  @media (max-width: 768px) {
    width: 100%; /* Full width on mobile */
    margin-bottom: 10px;
  }
`;


const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

const ActionButton = styled.button`
  background-color: #333333;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #444444;
  }

  svg {
    margin-right: 5px;
  }
`;

// Ensure images fit within their container
const GeneratedImage = styled.img`
  width: 100%;
  height: auto;
`;


const PlaceholderText = styled.p`
color: #888888; /* Medium gray text */
font-size: 18px;
text-align: center;
`;
const LoadingButton = styled.button`
  width: 100%;
  max-width: 300px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333; /* Dark gray background */
  color: #ffffff; /* White text */
  border: none;
  padding: 15px 25px; /* Increased padding */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px; /* Slightly increased margin-top */
  margin-bottom: 15px; /* Added margin-bottom */
  font-size: 18px; /* Increased font size */
  font-weight: bold; /* Made text bold */
  margin: 20px auto; /* Center the button horizontally */

  &:hover {
    background-color: #444444; /* Slightly lighter gray on hover */
  }

  &:disabled {
    background-color: #555555;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;


const CreateGeneratorWarning = styled.div`
background-color: #222222;  /* Very dark gray background */
color: #cccccc; /* Light gray text */
padding: 15px;
border-radius: 8px;
margin-top: 20px;
margin-bottom: 20px;
text-align: center;
font-weight: bold;
border: 1px solid #555555; /* Dark gray border */
`;

const GalleryPromptContainer = styled.div`
padding: 10px;
background-color: #222222; /* Dark gray background */
display: flex;
justify-content: space-between;
align-items: center;

@media (max-width: 768px) {
  padding: 5px;
}
`;


const EarlyAccessBadge = styled.span`
  background-color: #ffd700;
  color: #333;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 2px 4px;
  margin-right: 10px;
  border-radius: 10px;
  margin-left: 4px;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;

  @media (min-width: 768px) {
    font-size: 0.8rem;
    padding: 3px 8px;
    border-radius: 12px;
    margin-left: 8px;
  }
`;

const CustomSizeInputs = styled.div`
display: flex;
gap: 10px;
margin-top: 10px;
flex-wrap: wrap;
`;

const CustomSizeInputWrapper = styled.div`
display: flex;
align-items: center;
flex: 1;
min-width: 120px;
`;

const CustomSizeLabel = styled.span`
color: #ffffff; /* White text */
margin-right: 5px;
min-width: 20px;
`;

const CustomSizeInput = styled(Input)`
width: 100%;
max-width: 80px;
margin-left: 5px;
background-color: #333333; /* Dark gray background */
color: #ffffff; /* White text */
`;

// Continue updating other components similarly...

const SignOutContainer = styled.div`
margin-top: 30px;
text-align: center;
`;

const ButtonContainer = styled.div`
display: flex;
flex-wrap: wrap;
gap: 10px;
  
/* Adjust for mobile devices */
@media (max-width: 768px) {
  flex-direction: column;
  align-items: center;
}
`;

const SettingsButton = styled.button`
background-color: #333333; /* Dark gray background */
color: #ffffff; /* White text */
padding: 10px 15px;
border: none;
border-radius: 5px;
cursor: pointer;
flex: 1;
text-align: center;

&:hover {
  background-color: #444444; /* Slightly lighter on hover */
}
  
@media (max-width: 768px) {
  width: 100%;
}
`;

const CancelButton = styled(SettingsButton)`
background-color: #222222; /* Even darker gray */

&:hover {
  background-color: #333333;
}
`;

const LogoutButton = styled(SettingsButton)`
background-color: #000000; /* Black background */

&:hover {
  background-color: #111111;
}
`;
const ExampleUseCase = styled.div`
  background-color: #1a1a1a; /* Black background */
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  color: #cccccc; /* Light gray text */

  @media (max-width: 768px) {
    padding: 0;
    border-radius: 0;
    background-color: #000000; /* Black background */
  }
`;
const ExampleTitle = styled.h3`
  color: #cccccc; /* Light gray text */
  margin-bottom: 10px;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;




const SidebarButton = styled.button`
  background: none;
  border: none;
  color: #ecf0f1; /* Light text color */
  padding: 10px;
  margin-bottom: 5px;
  text-align: left;
  font-size: 19px;
  cursor: pointer;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #333333; /* Dark gray */
  }

  ${(props) =>
    props.active &&
    `
      background-color: #444444; /* Darker gray */
    `}
`;

// Adjusted SidebarMenu to include new tabs


const SidebarMenu = styled.nav`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

// Update the color scheme to black and gray
const SidebarContainer = styled.div`
  width: 180px;
  min-width: 180px;
  background-color: #1a1a1a; /* Black background */
  color: #ecf0f1; /* Light text color */
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (max-width: 768px) {
    display: none; /* Hide sidebar on mobile */
  }
`;

// Update o

// Adjusted styled components
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  background-color: #050505; /* Black background */
  z-index: 100;
  position: relative;
`;

const SidebarToggleButton = styled.button`
  background: none;
  border: none;
  color: #cccccc; /* Light gray text */
  font-size: 24px;
  cursor: pointer;

  @media (min-width: 769px) {
    display: none; /* Hide toggle button on desktop */
  }
`;

const HeaderTitle = styled.h1`
  color: #ecf0f1;
  font-size: 24px;
  margin: 0;
  flex-grow: 1;
  text-align: center;

  @media (max-width: 768px) {
    text-align: left;
    margin-left: 10px;
  }
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
  }
`;

const ProfileIcon = styled.div`

  display: flex;
  align-items: center;
  cursor: pointer;

  cursor: pointer;
  color: #ecf0f1;
  font-size: 36px;
  position: relative;


`;




const AppContainer = styled.div`
  display: flex;
  height: 100vh;
`;


const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

/* Main Content */
const MainContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
`;
const DropdownMenu = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #1a1a1a; /* Black background */
  border: 1px solid #444444; /* Dark gray border */
  border-radius: 4px;
  width: 200px; /* Adjusted width */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  z-index: 101;
`;

const MobileSidebarOverlay = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 150;
`;


const DropdownItem = styled.div`
  padding: 10px 15px;
  color: #cccccc; /* Light gray text */
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #333333; /* Dark gray background on hover */
  }
`;
const DropdownDivider = styled.div`
  height: 1px;
  background-color: #444444; /* Dark gray divider */
  margin: 5px 0;
`;

const ButtonText = styled.span`
  margin-left: 10px;
`;








const DiscordFooter = styled.div`
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #444444; /* Dark gray border */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DiscordLink = styled.a`
  display: flex;
  align-items: center;
  color: #888888; /* Medium gray text */
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease;

  &:hover {
    color: #cccccc; /* Light gray text on hover */
  }
`;

const DiscordIcon = styled(FaDiscord)`
  margin-right: 10px;
  font-size: 24px;
`;

const CreditSection = styled.div`
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid #444444; /* Dark gray border */
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    position: fixed;
    top: 10px;
    right: 10px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
    width: auto;
    background-color: rgba(26, 26, 26, 0.8);
    border-radius: 20px;
  }
`;

const CreditDisplay = styled.span`
  font-weight: bold;
  color: #ecf0f1;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-right: 5px;
  }
`;


const BuyCreditsButton = styled.button`
  background-color: #333333; /* Dark gray background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #444444; /* Slightly lighter on hover */
  }

  @media (max-width: 768px) {
    width: 16px;
    height: 16px;
    font-size: 14px;
  }
`;



const Title = styled.h1`
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;



const InputContainer = styled.div`
  padding-right: 20px;
  margin-bottom: 20px;
`;


const SortButton = styled.button`
  background: none;
  border: none;
  color: #999999;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 10px;

  &:hover {
    color: #2980b9;
  }
`;

const SortIcon = styled(FaSort)`
  margin-left: 5px;
`;

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const EstimatedTime = styled.span`
  font-style: italic;
  color: #7f8c8d;
  margin-left: 10px;
`;

const GeneratorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const GeneratorCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

// Add this with your other styled components
const GenerateButton = styled(Button)`
  margin-top: 10px;
  background-color: #333333; /* Dark gray background */
  color: #ffffff; /* White text */
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #444444; /* Slightly lighter on hover */
  }
`;

const GeneratorNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
`;

const LargeReactIcon = styled(FaUpload)`
  font-size: 48px;
  color: #cccccc; /* Light gray color */
  margin-top: 10px;
`;

const GeneratorName = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0 0;
  flex-grow: 1;
  padding-right: 5px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const EditButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #999999;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  &:hover {
    color: #2980b9;
  }
`;

const EditNameInput = styled.input`
  width: calc(100% - 60px);
  padding: 5px;
  border: 1px solid #3498db;
  border-radius: 4px;
  background-color: #34495e;
  color: #ecf0f1;
  font-size: 14px;
`;


const EditNameButton = styled.button`
  background-color: ${props => props.confirm ? '#2ecc71' : '#e74c3c'};
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;

  &:hover {
    background-color: ${props => props.confirm ? '#27ae60' : '#c0392b'};
  }
`;

const GeneratorImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 100%;
    height: 200px; // Increased height for mobile
    margin-bottom: 5px;
  }
`;

const CreateGeneratorSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  background-color: #1a1a1a;  /* Black background */
  padding: 20px;
  border-radius: 8px;
  color: #cccccc;  /* Light gray text */
`;


// Update the CreateGeneratorForm component (no color changes needed)
const CreateGeneratorForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
`;

// Update the FormBox component
const FormBox = styled.div`
  background-color: #222222;  /* Dark gray background */
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
`;


const Guidance = styled.p`
  color: #bdc3c7;  // Light gray for secondary text
  font-style: italic;
  margin: 10px 0;
  text-align: center;
`;
const CreateGeneratorButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  max-width: 300px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333; /* Dark gray background */
  color: #ffffff; /* White text */
  border: none;
  padding: 15px 25px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 18px;
  font-weight: bold;

  &:hover {
    background-color: #444444; /* Slightly lighter on hover */
  }

  &:disabled {
    background-color: #555555;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;


const LoadingSpinner = styled.div`
  border: 2px solid #333333; /* Dark gray background */
  border-top: 2px solid #cccccc; /* Light gray for the spinning part */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 8px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const GeneratorGuide = styled.div`
  background-color: #1a1a1a; /* Dark grey, almost black background */
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  color: #cccccc; /* Light grey text */
`;



const GuideText = styled.p`
  color: #cccccc; /* Light gray text */
  margin-bottom: 10px;
`;
const CreateCustomGeneratorButton = styled(Button)`
  background-color: #333333; /* Dark gray background */
  color: #ffffff; /* White text */
  border: none;
  padding: 15px 25px; /* Increased padding */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px; /* Slightly increased margin-top */
  margin-bottom: 15px; /* Added margin-bottom */
  font-size: 18px; /* Increased font size */
  font-weight: bold; /* Made text bold */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #444444; /* Slightly lighter gray on hover */
  }

  svg {
    margin-right: 10px; /* Increased space between icon and text */
    font-size: 24px; /* Made icon larger */
  }
`;



const LoadingGeneratorCard = styled(GeneratorCard)`
  opacity: 0.7;
  cursor: not-allowed;
`;

const LoadingText = styled.p`
  font-style: italic;
  color: #bdc3c7;
`;
const ErrorMessage = styled.div`
  color: #cccccc; /* Light gray text */
  background-color: #1a1a1a; /* Black background */
  border: 1px solid #444444; /* Dark gray border */
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;


   // After your existing styled components
   const ProgressBarContainer = styled.div`
     width: 100%;
     height: 20px;
     background-color: #333333; /* Dark gray background */
     border-radius: 8px;
     overflow: hidden;
     margin-top: 10px;
   `;

   const ProgressBar = styled.div`
     height: 100%;
     background-color: #00bcd4; /* Teal color */
     transition: width 0.5s ease;
     width: ${(props) => props.progress}%;
   `;

const CreditsEarnedSection = styled.div`
  margin-top: 20px;
  background-color: #1a1a1a; /* Black background */
  padding: 15px;
  border-radius: 8px;
  color: #cccccc; /* Light gray text */
  text-align: center;
`;

const CreditsEarnedHeading = styled.h3`
  margin-top: 0;
`;
// After
const CreditsEarnedValue = styled.p`
  font-size: 32px;
  margin: 10px 0;
  color: #cccccc; /* Light gray text */
`;
const GenerationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const GenerationInput = styled(Input)`
  width: 97%;
  // for mobile
  @media (max-width: 768px) {
    width: 91%;
  }
`;










const GenerationSelect = styled.select`
  width: 99%;
  padding: 8px;
  border: 1px solid #555555; /* Dark gray border */
  border-radius: 4px;
  background-color: #222222; /* Dark gray background */
  color: #cccccc; /* Light gray text */
`;// Update the SuccessMessage component
const SuccessMessage = styled.div`
  color: #cccccc;  /* Light gray text */
  background-color: #1a1a1a;  /* Black background */
  margin-top: 20px;
  font-size: 18px;
  text-align: center;
`;

const ImageContainer = styled.div`
width: 100%;
min-height: 200px;
max-width: 95 0px;
margin-bottom: 20px;
border: 1px solid #555555;  // Match the border color with the theme
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
position: relative;
background-color: #111111;  // Dark background to match the theme
padding: 0px;
flex-direction: column;

@media (max-width: 768px) {
  padding: 0px;
}
`;


// After
const BoxContainer = styled.div`
  margin-top: 20px;
  background-color: #1a1a1a; /* Black background */
  padding: 20px;
  border-radius: 8px;
  color: #cccccc;  /* Light gray text */
`;
const GalleryPrompt = styled.p`
  margin: 0;
  font-size: 14px;
  color: #ecf0f1;
  flex-grow: 1;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const CopyIconWrapper = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CopyIcon = styled(FaCopy)`
  color: #bdc3c7;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    color: #ecf0f1;
  }
`;

const DeleteWarning = styled.p`
  color: #888;
  font-style: italic;
  text-align: center;
  margin-bottom: 20px;
`;

const LoadMoreButton = styled(Button)`
  display: block;
  margin: 20px auto 0;
`;




const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const ImagePreview = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
`;
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const ModalContent = styled.div`
  background-color: #1a1a1a; /* Black background */
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
`;
const ModalImage = styled.img`
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ecf0f1;
  font-size: 24px;
  cursor: pointer;
`;

const ModalPrompt = styled.p`
  color: #ecf0f1;
  margin-top: 10px;
  text-align: center;
`;
const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;
// Update the DeleteIcon component
const DeleteIcon = styled(FaTrash)`
  position: absolute;
  top: 5px;
  right: 5px;
  color: #ffffff;  /* White icon */
  background-color: rgba(0, 0, 0, 0.5);  /* Semi-transparent black background */
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
`;


const EditNameButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
`;
const SearchInput = styled.input`
  width: 100%;
  padding: 10px 30px 10px 10px;
  border: 1px solid #444444; /* Dark gray border */
  border-radius: 4px;
  background-color: #222222; /* Dark gray background */
  color: #cccccc; /* Light gray text */
  font-size: 16px;

  &::placeholder {
    color: #888888; /* Medium gray placeholder */
  }
`;


const SearchIcon = styled(FaSearch)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #bdc3c7;
`;


const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }
`;

const GalleryItem = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 768px) {
    border-radius: 4px;
  }
`;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;



// Additional styled components for the inpainting feature
const EditContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const ImageUploadSection = styled.div`
  flex: 1;
  min-width: 300px;
`;

const PromptSection = styled.div`
  flex: 1;
  min-width: 300px;
`;

const GeneratedImages = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;




const ModalHeader = styled.h2`
  margin-top: 0;
  color: #ecf0f1;
`;

const ModalBody = styled.div`
  color: #ecf0f1;
  margin: 20px 0;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const ConfirmButton = styled.button`
  background-color: #333333; /* Dark gray background */
  color: #cccccc; /* Light gray text */
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #444444; /* Slightly lighter on hover */
  }
`;







const GalleryImageContainer = styled.div`
  position: relative;
  height: 250px;
  cursor: pointer; // Add this line

  @media (max-width: 768px) {
    height: 150px;
  }
`;



const EditorSection = styled.div`
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 20px;
  margin-top: 20px;
  
    @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const EditorSubSection = styled.div`
  flex: 1;
  min-width: 300px;

    @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #cccccc;
`;

const Instruction = styled.p`
  margin-top: 10px;
  color: #999999;
`;


// Define the subscriptions object
const subscriptions = {
  freeTrial: {
    name: 'Starter',
    monthlyPrice: '$0',
    yearlyPrice: '$0',
    credits: '5 credits',
    estimatedPictures: 33,
    estimatedModels: 1,
    fixedYearlyCost: 0,
    monthlyStripePriceId: 'price_1Q1Uu303eI7eG1NfZNBdsS7Q',
    yearlyStripePriceId: 'price_1Q1Uu303eI7eG1NfZNBdsS7Q',
  },
  basic: {
    name: 'monthly' ? 'Creator monthly' : 'Creator yearly',
    monthlyPrice: '$20',
    yearlyPrice: '$180',
    credits: 'monthly' ? '20 credits / month' : '240 credits / year',
    estimatedPictures: 'monthly' ? 133 : 1600,
    estimatedModels: 80,
    fixedYearlyCost: 0.113,
    monthlyStripePriceId: 'price_1Q4jme03eI7eG1NfECAA6XIz',
    yearlyStripePriceId: 'price_1Q4jme03eI7eG1NfF99lgCZb',
  },
  pro: {
    name: 'monthly' ? 'Artist monthly' : 'Artist yearly',
    monthlyPrice: '$60',
    yearlyPrice: '$540',
    credits: 'monthly' ? '60 credits / month' : '720 credits / year',
    estimatedPictures: 'monthly' ? 400 : 4800,
    estimatedModels: 240,
    monthlyStripePriceId: 'price_1Q4jnq03eI7eG1NfTPKU9IbO',
    yearlyStripePriceId: 'price_1Q4jnq03eI7eG1NfkvRvNUmz',
  },
  enterprise: {
    name: 'monthly' ? 'Visionary monthly' : 'Visionary yearly',
    monthlyPrice: '$180',
    yearlyPrice: '$1620',
    credits: 'monthly' ? '180 credits / month' : '2160 credits / year',
    estimatedPictures: 'monthly' ? 1200 : 14400,
    estimatedModels: 720,
    monthlyStripePriceId: 'price_1Q4jl103eI7eG1NfEKrhiCR9',
    yearlyStripePriceId: 'price_1Q4jl103eI7eG1NfqFfgWaAd',
  },
};

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


// Add these styled components with your existing styled components

// After
const SubscriptionInfo = styled.div`
  margin-bottom: 20px;
  color: #cccccc; /* Light gray text */
  p {
    margin: 5px 0;
  }
`;

// After
const ManageSubscription = styled.div`
  margin-bottom: 20px;
  color: #cccccc; /* Light gray text */
  h3 {
    margin-bottom: 10px;
  }
`;


const ExampleList = styled.ol`
  padding-left: 20px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    padding-left: 15px;
  }
`;

const ExampleListItem = styled.li`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 10px;
  }

  strong {
    display: block;
    margin-bottom: 5px;

    @media (max-width: 768px) {
      margin-bottom: 3px;
    }
  }
`;


// ... existing imports and code ...

const MobileSidebar = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? '0' : '-250px')};
  width: 250px;
  height: 100%;
  background-color: #1a1a1a;
  overflow-y: auto;
  transition: left 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);

  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileSidebarHeader = styled.div`
  padding: 0 20px 20px;
  border-bottom: 1px solid #333;
  margin-bottom: 20px;
`;

const MobileSidebarTitle = styled.h2`
  color: #cccccc;
  font-size: 24px;
  margin: 0;
`;

const MobileSidebarButton = styled(SidebarButton)`
  padding: 15px 20px;
  font-size: 18px;
  border-bottom: 1px solid #333;

  &:last-child {
    border-bottom: none;
  }
`;

const MobileSidebarFooter = styled.div`
  margin-top: auto;
  padding: 20px;
  border-top: 1px solid #333;
`;

const MobileProfileSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const MobileProfileIcon = styled(FaUserCircle)`
  font-size: 36px;
  color: #cccccc;
  margin-right: 10px;
`;

const MobileProfileName = styled.span`
  color: #cccccc;
  font-size: 18px;
`;





function WebApp() {

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]); // Change from single image to array
  const [showAdditionalSettings, setShowAdditionalSettings] = useState(false);

  // Add state to control the dropdown menu
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [successLog, setSuccessLog] = useState(null);
  const [newGenerator, setNewGenerator] = useState(null);
  const [user, setUser] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [authFinalized, setAuthFinalized] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [displayedGenerations, setDisplayedGenerations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 21;
  const [estimatedTime, setEstimatedTime] = useState(null);
  const generatorNameInputRef = useRef(null);
  const [showGenerationEstimate, setShowGenerationEstimate] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [showInsufficientCreditsWarning, setShowInsufficientCreditsWarning] = useState(false);
  const [customSize, setCustomSize] = useState({ width: 1024, height: 1024 });
  const [totalGenerations, setTotalGenerations] = useState(0);
  const [defaultGeneratorLastImage, setDefaultGeneratorLastImage] = useState(null);
  const [creatingGeneratorName, setCreatingGeneratorName] = useState(null);
  const [creatingGeneratorImage, setCreatingGeneratorImage] = useState(null);
  const [creditsLoaded, setCreditsLoaded] = useState(false);
  const [showAllExamples, setShowAllExamples] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [showCreditWarning, setShowCreditWarning] = useState(false);
  const [openFAQs, setOpenFAQs] = useState({});
  const [warningMessage, setWarningMessage] = useState(null);
  const [editingGenerator, setEditingGenerator] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedImage, setSelectedImage] = useState(null);
// Inside the WebApp component
const [inpaintingImageFile, setInpaintingImageFile] = useState(null);
const [maskImageFile, setMaskImageFile] = useState(null);
// Inside your component's main function
const [isGalleryVisible, setIsGalleryVisible] = useState(true);
const [subscriptionId, setSubscriptionId] = useState(null);
const [subscriptionStatus, setSubscriptionStatus] = useState(null);
const [subscriptionType, setSubscriptionType] = useState(null);
const [referralCode, setReferralCode] = useState('');
  const [referralLink, setReferralLink] = useState('');
  const [creditsEarned, setCreditsEarned] = useState(0);

  const [currentPlanName, setCurrentPlanName] = useState('');

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [planDetails, setPlanDetails] = useState({});

  const [activeTab, setActiveTab] = useState('yourGenerators');
  const [generators, setGenerators] = useState([]);
  const [currentGenerator, setCurrentGenerator] = useState(null);
  const [generations, setGenerations] = useState([]);
  const [credits, setCredits] = useState(null);
  const [credits_spent, setCredits_spent] = useState(null);
  const [generationsLoaded, setGenerationsLoaded] = useState(false);
  const [generatorsLoaded, setGeneratorsLoaded] = useState(false);
  const [newGeneratorName, setNewGeneratorName] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isCreatingGenerator, setIsCreatingGenerator] = useState(false);
  const [error, setError] = useState(null);

  const [inpaintingImage, setInpaintingImage] = useState(null);
  const [inpaintingImageDataUrl, setInpaintingImageDataUrl] = useState(null);
  const [maskImage, setMaskImage] = useState(null);
  const [maskImageDataUrl, setMaskImageDataUrl] = useState(null);
  const [selectedGenerationForEdit, setSelectedGenerationForEdit] = useState(null);

   // Inside the WebApp component
   const [progress, setProgress] = useState(0);

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  // Close mobile sidebar when clicking outside or on a link
  const closeMobileSidebar = () => {
    setIsMobileSidebarOpen(false);
  };
  



  const [generationParams, setGenerationParams] = useState({
    prompt: '',
    generator: '',
    image_size: isMobile ? 'portrait_16_9' : 'landscape_4_3',
    num_inference_steps: 50,
    guidance_scale: 3.5,
    num_images: 1,
    enable_safety_checker: false,
    output_format: 'png'
  });

  const auth = getAuth(); // Add this line


  const getPlanDetailsBySubscriptionId = (subscriptionId) => {
    for (const key in subscriptions) {
      const plan = subscriptions[key];
      if (plan.monthlyStripePriceId === subscriptionId || plan.yearlyStripePriceId === subscriptionId) {
        return plan;
      }
    }
    // If no match is found, return the Free Trial plan as default
    return subscriptions['freeTrial'];
  };

  const getHeaderTitle = () => {
    switch (activeTab) {
      case 'create':
        return 'Generate new image';
      case 'yourGenerators':
        return 'Your Generators';
      case 'generations':
        return 'Gallery';
      case 'yourPlan':
        return 'Your Plan';
      case 'earnCredits':
        return 'Refer a friend';
      case 'help':
        return 'Help';
      case 'settings':
        return 'Settings';
      case 'newGenerator':
        return 'Create New Generator';
        case 'edit':
          return 'Edit your image';
      default:
        return 'Your App';
    }
  };

    // Function to handle selection of a generation for editing
    const handleSelectGenerationForEdit = (generation) => {
      setSelectedGenerationForEdit(generation);
      setInpaintingImage(generation.image_url);
      setGenerationParams(prev => ({ ...prev, prompt: '' })); // Reset edit prompt
      setIsGalleryVisible(false); // Hide the gallery after selecting an image

    };

  useEffect(() => {
    if (authFinalized) {
      fetchUserPlanAndCredits();
    }
  }, [authFinalized]);

  useEffect(() => {
    let timer;
    if (isCreatingGenerator) {
      const startTime = Date.now();
      timer = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const progressPercentage = Math.min((elapsedTime / (135 * 1000)) * 100, 100);
        setProgress(progressPercentage);
        if (progressPercentage >= 100) {
          clearInterval(timer);
        }
      }, 1000); // Update every second
    } else {
      setProgress(0);
    }
    return () => clearInterval(timer);
  }, [isCreatingGenerator]);

  const fetchUserPlanAndCredits = async () => {
    try {
      const response = await axios.get(`${API_URL}/user/plan`, {
        params: { user_id: user.uid },
      });
      setCurrentPlanName(response.data.planName);
      setCredits(response.data.credits);
    } catch (error) {
      console.error('Error fetching user plan and credits:', error);
    }
  };


  const handleUpgrade = () => {
    // Redirect user to the Stripe Billing Portal
    credits_spent > 5 ? window.location.href = 'https://billing.stripe.com/p/login/8wMcQj7fob0Z0Mw3cc' : navigate('/checkout');
  };

  const handleDowngrade = () => {
    // Redirect user to the Stripe Billing Portal
    window.location.href = 'https://billing.stripe.com/p/login/8wMcQj7fob0Z0Mw3cc';
  };

  const handleCancelPlan = () => {
    // Show the confirmation modal
    setShowCancelConfirmation(true);
  };


  // Handle inpainting image upload
  const handleInpaintingImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setInpaintingImage(url);
      setInpaintingImageFile(file); // Save the file object

      // Optional: Convert to Data URL if needed elsewhere
      fileToDataURL(file, (dataUrl) => setInpaintingImageDataUrl(dataUrl));
    }
  };


  // Convert file to Data URL
  const fileToDataURL = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (e) => callback(e.target.result);
    reader.readAsDataURL(file);
  };



  // Handle mask change from the ImageEditor component
  const handleMaskChange = (maskDataUrl, maskBlob) => {
    setMaskImage(maskDataUrl);
  // Create a File object from the Blob with the correct MIME type
    const maskFile = new File([maskBlob], 'mask.png', { type: 'image/png' });
    setMaskImageFile(maskFile);
    // Optional: Save Data URL if needed elsewhere
    setMaskImageDataUrl(maskDataUrl);
  };



  // // Handle generation parameters change
  // const handleGenerationParamChange = (event) => {
  //   const { name, value } = event.target;
  //   setGenerationParams(prev => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

 // Modify the generateInpaintingImage function
 const generateInpaintingImage = async () => {
  if (!selectedGenerationForEdit) {
    setError("Please select an image from your gallery to edit.");
    return;
  }

  if (!generationParams.prompt.trim()) {
    setError("Please enter editing instructions before generating an image.");
    return;
  }

  if (!maskImageFile) {
    setError("Please brush over the area you want to edit.");
    return;
  }
   
  setError(null);
  setGeneratedImages([]); // Clear previous images
  setIsGenerating(true);
  setShowGenerationEstimate(true);

  try {
    const formData = new FormData();
    formData.append('user_id', user.uid);
    formData.append('generation_id', selectedGenerationForEdit.id); // Include generation_id
    formData.append('prompt', generationParams.prompt); // Use editPrompt
    formData.append('model', selectedGenerationForEdit.modelId || "DEFAULT"); // Use the model used during generation
    formData.append('image_url', selectedGenerationForEdit.image_url);
    formData.append('mask_file', maskImageFile);
    formData.append('num_inference_steps', generationParams.num_inference_steps || 50);
    formData.append('guidance_scale', generationParams.guidance_scale || 3.5);
    formData.append('enable_safety_checker', generationParams.enable_safety_checker || false);
    formData.append('output_format', generationParams.output_format || "jpeg");

    // Log formData for debugging
    // for (let pair of formData.entries()) {
    //   console.log(`${pair[0]}: ${pair[1]}`);
    // }

    const response = await axios.post(
      `${API_URL}/edit`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    const data = response.data;
    if (!data.image_url) {
      throw new Error('No image URL returned from the server.');
    }

       // Add the new edited image to the generations array
       setGenerations(prevGenerations => [
        {
          id: data.generation_id, // Assuming the API returns a generation_id
          image_url: data.image_url,
          prompt: generationParams.prompt,
          created_at: new Date().toISOString(), // Or use data.created_at if provided
          modelId: selectedGenerationForEdit.modelId || "DEFAULT",
        },
        ...prevGenerations,
      ]);

    setGeneratedImages([data.image_url]);
    

    fetchCredits(); // Refresh credits after generation
  } catch (err) {
    console.error('Error during inpainting:', err);
    setError('An error occurred during inpainting. Please try again.');
  } finally {
    setIsGenerating(false);
    setShowGenerationEstimate(false);
  }
};

  const handleDeleteGeneration = async (generationId) => {
    const confirmed = window.confirm("Are you sure you want to delete this image?");
    if (!confirmed) return;

    try {
      const idToken = await user.getIdToken();

      const response = await axios.post(
        `${API_URL}/generations/${generationId}/delete`,
        null, // No data to send in the body
        {
          params: {
            user_id: user.uid, // Use the authenticated user's UID
          },
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.data.success) {
        // Remove the deleted generation from the state
        setGenerations(generations.filter((gen) => gen.id !== generationId));
      } else {
        alert("Failed to delete the image.");
      }
    } catch (error) {
      console.error("Error deleting generation:", error);
      alert("An error occurred while deleting the image.");
    }
  };

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest && !event.target.closest('.profile-dropdown')) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  


  const handleDeleteGenerator = async (generatorId) => {
    const confirmed = window.confirm("Are you sure you want to delete this generator?");
    if (!confirmed) return;

    try {
      const idToken = await user.getIdToken();

      const response = await axios.post(
        `${API_URL}/models/${generatorId}/delete`,
        null, // No data to send in the body
        {
          params: {
            user_id: user.uid, // Use the authenticated user's UID
          },
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.data.success) {
        // Remove the deleted generator from the state
        setGenerators(generators.filter((gen) => gen.id !== generatorId));
      } else {
        alert("Failed to delete the generator.");
      }
    } catch (error) {
      console.error("Error deleting generator:", error);
      alert("An error occurred while deleting the generator.");
    }
  };
 
  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };



  const navigate = useNavigate();


  // useEffect(() => {
  //   if (subscriptionStatus === 'canceled') {
  //     navigate('/checkout');
  //   }
  // }, [subscriptionStatus, navigate]);


  const API_URL = 'https://fastapi-app-ok2y6f2snq-uc.a.run.app'; // Update this with your local server URL

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setAuthFinalized(true);
      if (currentUser) {
        // console.log('Current user:', currentUser);
        // console.log('User email:', currentUser.email);
        // console.log('User UID:', currentUser.uid);
      } else {
        // console.log('No user is signed in.');
        navigate('/'); // Redirect to landing page
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (authFinalized && user) {
      fetchCredits();
      fetchGenerators();
      setDefaultGeneratorLastImage(defaultGeneratorImage);
    }
  }, [authFinalized, user]);
  

  useEffect(() => {
    if (referralCode) {
      setReferralLink(`https://pictrain.ai/?r=${referralCode}`);
    }
  }, [referralCode]);

  const getPlanDetails = (subscriptionType) => {
    // Map the subscriptionType to the corresponding key in subscriptions
    let subscriptionKey = '';
    switch (subscriptionType.toLowerCase()) {
      case 'free trial':
        subscriptionKey = 'freeTrial';
        break;
      case 'basic':
        subscriptionKey = 'basic';
        break;
      case 'pro':
        subscriptionKey = 'pro';
        break;
      case 'enterprise':
        subscriptionKey = 'enterprise';
        break;
      default:
        subscriptionKey = 'freeTrial';
    }

    return subscriptions[subscriptionKey] || subscriptions['freeTrial'];
  };

  const formatNumber = (num) => {
    return Number(num).toLocaleString();
  };


  
  const fetchCredits = async () => {
    try {
      if (!user || !user.uid) {
        console.error('No user or user ID available');
        setError('User not authenticated');
        setCreditsLoaded(true);
        return;
      }

      const idToken = await user.getIdToken();
      const response = await axios.get(`${API_URL}/credits?user_id=${user.uid}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      if (response.data) {
        setCredits(response.data.credits);
        setCredits_spent(response.data.credits_spent);
        setCreditsLoaded(true);
        setCreditsEarned(response.data.credits_earned)

        setReferralCode(response.data.referral_code || '');

        const subscriptionId = response.data.subscription_id;
        setSubscriptionId(subscriptionId);
        setSubscriptionStatus(response.data.subscription_status);

        const planDetails = getPlanDetailsBySubscriptionId(subscriptionId);
        setPlanDetails(planDetails);
      } else {
        console.error('Invalid credits data:', response.data);
        setError('Invalid credits data received');
      }
    } catch (err) {
      console.error('Error fetching credits:', err);
      if (err.response) {
        console.error('Error response:', err.response);
        console.error('Error response data:', err.response.data);
      }
      setError('Failed to fetch credits: ' + (err.response ? JSON.stringify(err.response.data) : err.message));
    } finally {
      setCreditsLoaded(true);
    }
  };

  const fetchGenerators = async () => {
    try {
      const response = await axios.get(`${API_URL}/models?user_id=${user.uid}`);
      // Sort the generators alphabetically by name
      const sortedGenerators = response.data.sort((a, b) => a.name.localeCompare(b.name));
      setGenerators(sortedGenerators);
      setGeneratorsLoaded(true);
      
      // If the user has no generators, set the active tab to 'newGenerator'
      if (sortedGenerators.length === 0) {
        setActiveTab('newGenerator');
      } else {
        setGenerationParams(prev => ({ ...prev, generator: "DEFAULT" }));
      }
    } catch (err) {
      setError('Failed to fetch generators');
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'generations' && !generationsLoaded) {
      fetchGenerations();
    }

      if (tab === 'edit') {
        // Reset inpainting states
        setInpaintingImage(null);
        setInpaintingImageFile(null);
        setMaskImage(null);
        setMaskImageFile(null);
        setGeneratedImages([]);
        setGenerationParams(prev => ({ ...prev, prompt: '', generator: 'DEFAULT' }));
      
          // Load generations if not already loaded
    if (!generationsLoaded) {
      fetchGenerations();
    }
      }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // console.log("User signed out successfully");
      navigate('/'); // Redirect to landing page after logout
    } catch (error) {
      console.error("Error signing out:", error);
      // Handle errors here, such as displaying an error message to the user
    }
  };

  const fetchGenerations = async (page = 1) => {
    try {
      const response = await axios.get(`${API_URL}/generations?user_id=${user.uid}&page=${page}&per_page=${imagesPerPage}`);
      const fetchedGenerations = response.data.generations;
      setTotalGenerations(response.data.total_count);
      
      if (page === 1) {
        // Merge locally added generations with fetched generations
        const mergedGenerations = [...generations.filter(gen => !gen.id), ...fetchedGenerations];
        setGenerations(mergedGenerations);
      } else {
        setGenerations(prevGenerations => [...prevGenerations, ...fetchedGenerations]);
      }
      
      setGenerationsLoaded(true);
      setCurrentPage(page);
    } catch (err) {
      setError('Failed to fetch generations');
    }
  };


  useEffect(() => {
    if (
      credits !== null &&
      credits_spent !== null &&
      credits === 0 &&
      credits_spent === 0
    ) {
      navigate('/checkout');
    }
  }, [credits, credits_spent, navigate]);


  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = [...selectedFiles, ...files];
    if (newFiles.length > 20) {
      setError('You can upload a maximum of 20 images.');
      return;
    }
    setError(null);
    setSelectedFiles(newFiles);
    
    // Generate preview URLs for the new files
    const newPreviews = files.map(file => URL.createObjectURL(file));
    setPreviewImages(prevPreviews => [...prevPreviews, ...newPreviews]);
  };

  const removeImage = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setPreviewImages(prevPreviews => prevPreviews.filter((_, i) => i !== index));
  };


  const compressImagesIfNeeded = async (files) => {
    const MAX_SIZE_MB = 30; // Leave some buffer for other form data
    let totalSize = files.reduce((sum, file) => sum + file.size, 0);
    
    if (totalSize <= MAX_SIZE_MB * 1024 * 1024) {
      return files; // No compression needed
    }
  
    const options = {
      maxSizeMB: MAX_SIZE_MB / files.length,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
  
    const compressedFiles = await Promise.all(
      files.map(file => imageCompression(file, options))
    );
  
    return compressedFiles;
  };
  


  const sortGenerators = (generators) => {
    return [...generators].sort((a, b) => {
      if (sortOption === 'name') {
        return sortDirection === 'asc' 
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (sortOption === 'lastUsed') {
        // Assuming lastUsed is already a comparable value (like a timestamp)
        const aTime = a.lastUsed || 0;
        const bTime = b.lastUsed || 0;
        return sortDirection === 'asc' ? aTime - bTime : bTime - aTime;
      }
      return 0;
    });
  };

  const toggleSort = (option) => {
    if (sortOption === option) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOption(option);
      setSortDirection('asc');
    }
  };

  const createGenerator = async () => {
    setWarningMessage(null);
    
    if (!newGeneratorName.trim()) {
      setWarningMessage("Please enter a name for your generator.");
      return;
    }

    if (selectedFiles.length < 5) {
      setWarningMessage("Please upload at least 5 high-resolution diverse images to create a generator.");
      return;
    }

    const generatorName = newGeneratorName.trim() || "My Generator";
    if (selectedFiles.length === 0) return;
    
    if (credits < 3) {
      setShowCreditWarning(true);
      return;
    }

    setIsCreatingGenerator(true);
    setCreatingGeneratorName(generatorName);
    setCreatingGeneratorImage(URL.createObjectURL(selectedFiles[0]));
    setError(null);
    setSuccessLog(null);
    setShowInsufficientCreditsWarning(false);

    const estimatedMinutes = selectedFiles.length < 5 ? 2 : 3;
    setEstimatedTime(estimatedMinutes);

    try {
      const compressedFiles = await compressImagesIfNeeded(selectedFiles);
      const formData = new FormData();
      compressedFiles.forEach((file, index) => {
        formData.append('files', file);
      });
      formData.append('modelName', generatorName);
      formData.append('user_id', user.uid);

      // console.log('Sending request to create generator...');

      const response = await axios.post(`${API_URL}/process-images`, formData, {
        headers: { 
          'Content-Type': 'multipart/form-data'
        },
        timeout: 900000, // 15 minutes timeout
      });

      if (response.data && response.data.status === 'success') {
        setNewGenerator(response.data);
        setSuccessLog(true);
        
        await fetchGenerators();

        setGenerators(prevGenerators =>
          prevGenerators.map(gen => {
            if (gen.id === response.data.id) {
              return { ...gen, lastGeneratedImageUrl: previewImages[0] };
            } else {
              return gen;
            }
          })
        );

      } else {
        console.error('Unexpected response:', response);
        throw new Error(response.data?.message || 'Generator creation failed');
      }
    } catch (err) {
      console.error('Error creating generator:', err);
      const errorMessage = err.response?.data?.detail || err.message;
      if (errorMessage.includes('Insufficient credits')) {
        setShowInsufficientCreditsWarning(true);
        await fetchCredits();
      } else {
        setError(errorMessage + ". Please contact support with the error you are seeing.");
      }
    } finally {
      setIsCreatingGenerator(false);
      setCreatingGeneratorName(null);
      setCreatingGeneratorImage(null);
      setEstimatedTime(null);
    }
  };



  const generateImage = async () => {
    if (!generationParams.prompt.trim()) {
      // Add this check at the beginning of the function
      setError("Please enter a text prompt before generating an image.");
      return;
    }


    setError(null);
    setGeneratedImages([]); // Clear previous images
    setIsGenerating(true);
    setShowGenerationEstimate(true);

    try {
      const generatorToUse = generationParams.generator || "DEFAULT";
      const response = await axios.post(`${API_URL}/generate?user_id=${user.uid}`, {
        ...generationParams,
        model: generatorToUse
      });

      const newGenerations = response.data; // Assume response contains an array of images
      if (!newGenerations || newGenerations.length === 0) {
        throw new Error('No images returned from the server.');
      }

      // Add the new generations to the beginning of the generations array
      setGenerations(prev => [...newGenerations, ...prev]);
      setTotalGenerations(prev => prev + newGenerations.length);
      setGeneratedImages(newGenerations.map(gen => gen.image_url));

      // Update the last generated image for the generator
      const lastImageUrl = newGenerations[0].image_url;
      if (generatorToUse === "DEFAULT") {
        setDefaultGeneratorLastImage(lastImageUrl);
      } else {
        setGenerators(prevGenerators => prevGenerators.map(generator => 
          generator.id === generatorToUse ? { ...generator, lastGeneratedImageUrl: lastImageUrl } : generator
        ));
      }

      fetchCredits(); // Refresh credits after generation
    } catch (err) {
      console.error('Error generating images:', err);
      const errorMessage = err.response?.data?.detail || err.message;
      if (errorMessage.includes('Insufficient credits')) {
        setError('insufficient_credits');
        await fetchCredits(); // Refresh credits after insufficient credits error
      } else {
        setError(errorMessage + ". Please contact support with the error you are seeing.");
      }
    } finally {
      setIsGenerating(false);
      setShowGenerationEstimate(false);
    }
  };

  const handleEditImage = (generation) => {
    setSelectedGenerationForEdit(generation);
    setActiveTab('edit');
    setIsGalleryVisible(false);
  };

  const handleGenerationParamChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'image_size' && value === 'custom') {
      setGenerationParams(prev => ({
        ...prev,
        [name]: value,
        [name]: type === 'checkbox' ? checked : value,
        width: customSize.width,
        height: customSize.height
      }));
    } else {
      setGenerationParams(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleCustomSizeChange = (e) => {
    const { name, value } = e.target;
    setCustomSize(prev => ({
      ...prev,
      [name]: parseInt(value, 10)
    }));
    if (generationParams.image_size === 'custom') {
      setGenerationParams(prev => ({
        ...prev,
        [name]: parseInt(value, 10)
      }));
    }
  };

  const buyCredits = () => {
    navigate('/checkout');
  };


  
  const handleDownload = async (imageUrl, prompt) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `generated_image_${prompt.substring(0, 21)}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
      setError('Failed to download image');
    }
  };

  const loadMoreImages = () => {
    fetchGenerations(currentPage + 1);
  };

  useEffect(() => {
    if (activeTab === 'newGenerator' && generatorNameInputRef.current) {
      generatorNameInputRef.current.focus();
    }
  }, [activeTab]);

  const Notification = styled.div`
  background-color: #1a1a1a; /* Black background */
  color: #cccccc; /* Light gray text */
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

  const NotificationText = styled.span`
    flex-grow: 1;
  `;
  const NotificationButton = styled(Button)`
  background-color: #333333; /* Dark gray background */
  color: #ffffff; /* White text */
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 5px;

  &:hover {
    background-color: #444444; /* Slightly lighter on hover */
  }
`;


const handleCopyReferralLink = () => {
  if (referralLink) {
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        alert('Referral link copied to clipboard!');
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  }
};

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // You can add a temporary success message here if you want
      // console.log('Text copied to clipboard');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const QuickStartGuide = styled.div`
  background-color: #1a1a1a; /* Black background */
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  color: #cccccc; /* Light gray text */
`;

  const GuideSection = styled.div`
    margin-bottom: 15px;
  `;
  const GuideTitle = styled.h3`
  color: #cccccc; /* Light gray text */
  margin-bottom: 10px;
`;
  const GuideList = styled.ol`
    padding-left: 20px;
  `;

  const GuideListItem = styled.li`
    margin-bottom: 5px;
  `;

  const FileInputContainer = styled(InputContainer)`
  border-style: dashed;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
`;

  const FileInput = styled.input`
    display: none;
  `;
 
const FileInputLabel = styled.label`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 15px 20px;
background-color: #333333; /* Dark gray background */
color: #cccccc; /* Light gray text */
border-radius: 8px;
cursor: pointer;
transition: background-color 0.3s ease;

&:hover {
  background-color: #444444; /* Slightly lighter on hover */
}
`;
const InsufficientCreditsWarning = styled.div`
  background-color: #1a1a1a; /* Black background */
  color: #cccccc; /* Light gray text */
  border: 1px solid #444444; /* Dark gray border */
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
`;
  const WarningText = styled.p`
    margin-bottom: 15px;
  `;
  const WarningButton = styled(Button)`
  background-color: #333333; /* Dark gray background */
  color: #cccccc; /* Light gray text */
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;

  &:hover {
    background-color: #444444; /* Slightly lighter on hover */
  }
`;

  const InsufficientCreditsError = styled.div`
    background-color: #34495e;  // Dark blue-gray background
    color: #ecf0f1;  // Light text color
    border: 1px solid #f39c12;  // Yellow border
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  `;
  const GetCreditsButton = styled(Button)`
  background-color: #333333; /* Dark gray background */
  color: #ffffff; /* White text */
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #444444; /* Slightly lighter on hover */
  }
`;

  const getCreditsButtonText = () => {
    return credits_spent > 0.3 ? "Upgrade plan" : "Upgrade plan";
  };
  const FAQSection = styled.div`
  margin-top: 20px;
  background-color: #1a1a1a; /* Black background */
  border-radius: 8px;
  padding: 20px;
`;
const FAQItem = styled.div`
  margin-bottom: 15px;
  border-bottom: 1px solid #444444; /* Dark gray border */
  padding-bottom: 15px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;
const FAQQuestion = styled.div`
color: #888888; /* Medium gray text */
margin-bottom: 10px;
cursor: pointer;
display: flex;
justify-content: space-between;
align-items: center;
font-size: 18px;
font-weight: bold;
transition: color 0.3s ease;

&:hover {
  color: #cccccc; /* Light gray text on hover */
}
`;
const FAQAnswer = styled.div`
  color: #cccccc; /* Light gray text */
  margin-top: 10px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  padding-left: 15px;
  border-left: 3px solid #444444; /* Dark gray border */
  font-size: 16px;
  line-height: 1.5;
`;

  const FAQIcon = styled.span`
    margin-left: 10px;
    transition: transform 0.3s ease;
    transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0)'};
  `;

  const FAQTitle = styled.h3`
    color: #ecf0f1;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  `;

  const toggleFAQ = (index) => {
    setOpenFAQs(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const faqData = [
    {
      question: "How can I add more credits?",
      answer: "Click the '+' button in the top right corner (mobile) or click the '+' button in the bottom left corner (desktop) and select the package you want to purchase."
    },
    {
      question: "Can I use the images I generate for commercial purposes?",
      answer: "Yes, you can use the images you generate for commercial purposes if you have purchased credits. However, please ensure that the content of the images does not infringe on any existing copyrights, trademarks, or other intellectual property rights. You are responsible for the images you generate and their usage."
    },
    {
      question: "What kind of images should I upload to create an AI generator?",
      answer: "You should upload a variety of images that show different angles, lighting, and expressions. This will help the generator learn the different variations of the subject. Aim for high-quality images that clearly represent the subject matter you want to generate."
    },
    {
      question: "How many images should I upload to create an AI generator?",
      answer: "We recommend uploading at least 5 images, but for optimal results, providing 10-20 images is ideal. The more high-quality images you upload, the better the generator will be at generating accurate and diverse images."
    },
    {
      question: "Can I generate images without creating a generator?",
      answer: "Yes, you can generate images without creating a generator by using the default generator. Note that the default generator is a general one and may not be suitable for all use cases. For personalized results, we recommend creating a custom AI generator."
    },
    {
      question: "Why am I getting network error when creating a generator?",
      answer: "If you're getting a network error when creating a generator, check your internet connection. Try using a stable WiFi connection instead of mobile data. If the issue persists, try using a desktop browser instead of a mobile device."
    },
    {
      question: "Can I choose a custom size for the image I want to generate?",
      answer: "Yes, you can choose a custom size for the image you want to generate. Select 'Custom size' in the image size dropdown menu and then enter the desired width and height in the input fields. The width must be between 3840 and 512 pixels. The height must be between 2160 and 512 pixels."
    },
    {
      question: "Can I delete my generators or generations?",
      answer: "Yes, you can delete your generators or generated images at any time. Simply click on the edit button for deleting generators. For generated images, go to the gallery and click on the image you want to delete."
    },
    {
      question: "How can I generate 4K images or HD images?",
      answer: "For 4K images select 'Custom size' in the image size dropdown menu and then enter width: 3840 and height: 2160. For HD images, do the same but with width: 1980 and height: 1080"
    },
  ];
// Update the SeeMoreButton component
const SeeMoreButton = styled.button`
  background: none;
  border: none;
  color: #999999; 
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  
  &:hover {
    text-decoration: underline;
  }
`;
  const ExampleText = styled.p`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.6;
  color: #cccccc; /* Light gray text */

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
  const WarningMessage = styled.div`
  color: #cccccc; /* Light gray text */
  background-color: #1a1a1a; /* Black background */
  border: 1px solid #999999; /* Dark gray border */
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
`;

  const handleEditGeneratorName = (generator) => {
    setEditingGenerator(generator.id);
    setNewGeneratorName(generator.name);
  };
  const handleSaveGeneratorName = async () => {
    try {
      const response = await axios.post(`${API_URL}/models/${editingGenerator}/rename`, 
        { name: newGeneratorName },
        { 
          params: { user_id: user.uid },
          headers: { 'Content-Type': 'application/json' }
        }
      );
      if (response.data.success) {
        setGenerators(prevGenerators =>
          prevGenerators.map(gen =>
            gen.id === editingGenerator ? { ...gen, name: newGeneratorName } : gen
          )
        );
        setEditingGenerator(null);
      }
    } catch (err) {
      setError('Failed to update generator name');
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredGenerators = generators.filter(generator =>
    generator.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <AppContainer>
      {authFinalized && creditsLoaded ? (
        
        <>
        {/* Sidebar for desktop */}
        <SidebarContainer>
        <SidebarMenu>
        <SidebarButton
                active={activeTab === 'yourGenerators'}
                onClick={() => handleTabClick('yourGenerators')}
              >
                <FaHome style={{ marginRight: '10px' }} /> Home
              </SidebarButton>
              <SidebarButton
                active={activeTab === 'create'}
                onClick={() => handleTabClick('create')}
              >
                <RiAiGenerate style={{ marginRight: '10px' }} /> Generate
              </SidebarButton>

              <SidebarButton
    active={activeTab === 'edit'}
    onClick={() => handleTabClick('edit')}
  >
    <FaPaintBrush style={{ marginRight: '10px' }} /> Edit
  </SidebarButton>

              <SidebarButton
                active={activeTab === 'generations'}
                onClick={() => handleTabClick('generations')}
              >
                <FaImage style={{ marginRight: '10px' }} /> Gallery
              </SidebarButton>


  
        </SidebarMenu>

        <CreditSection>
              <CreditDisplay>
                {credits !== null ? `${credits.toFixed(2)} credits` : 'Loading...'}
              </CreditDisplay>
              <BuyCreditsButton onClick={buyCredits}>
              <IoIosAdd />
              {/* Or use an appropriate icon */}
              </BuyCreditsButton>
            </CreditSection>
          </SidebarContainer>

      
          {/* Content Wrapper */}
          <ContentWrapper>
            {/* Header */}
            <Header>
              {/* Sidebar Toggle <Button for mobile */}
              <SidebarToggleButton onClick={() => setIsMobileSidebarOpen(true)}>
            <FaBars />
          </SidebarToggleButton>
          <HeaderTitle>{getHeaderTitle()}</HeaderTitle>          
          {!isMobile && (
            <ProfileHeader className="profile-dropdown">
              
              <ProfileIcon onClick={() => setIsDropdownOpen((prev) => !prev)}>
                {/* <EarlyAccessBadge>Early Access</EarlyAccessBadge> */}
                <FaUserCircle />

                </ProfileIcon>
              <DropdownMenu isOpen={isDropdownOpen}>
              <DropdownItem
                      onClick={() => {
                        setActiveTab('yourPlan');
                        setIsDropdownOpen(false);
                      }}
                    >
                      <FaGem style={{ marginRight: '8px' }} /> Your Plan
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setActiveTab('earnCredits');
                        setIsDropdownOpen(false);
                      }}
                    >
                      <FaDollarSign style={{ marginRight: '8px' }} /> Refer a friend
                    </DropdownItem>
                    {/* <DropdownItem
                      onClick={() => {
                        setActiveTab('settings');
                        setIsDropdownOpen(false);
                      }}
                    >
                      <FaCog style={{ marginRight: '8px' }} /> Settings
                    </DropdownItem> */}

<DropdownItem
               onClick={() => {
                 window.open('https://discord.gg/knkcgvzz', '_blank');
                 setIsDropdownOpen(false);
               }}
             >
               <FaDiscord style={{ marginRight: '8px' }} /> Community
             </DropdownItem>

                    <DropdownItem
                      onClick={() => {
                        setActiveTab('help');
                        setIsDropdownOpen(false);
                      }}
                    >
                      <FaQuestionCircle style={{ marginRight: '8px' }} /> Help
                    </DropdownItem>
                    <DropdownDivider />
                    <DropdownItem onClick={handleLogout}>
                      <FaSignOutAlt style={{ marginRight: '8px' }} /> Log Out
                    </DropdownItem>
                  </DropdownMenu>
            </ProfileHeader>
          )}
        </Header>
            <MainContent>
       {/* Content based on activeTab */}
       {activeTab === 'yourPlan' && (
                <Section>
                  {/* Content for 'Your Plan' tab */}
                  {/* Details about the user's plan */}
                
    <BoxContainer>
    <h3>Your plan</h3>

    <SubscriptionInfo>
      <p><strong>Current Plan:</strong> {planDetails.name}</p>
      <p><strong>Subscription Status:</strong> {subscriptionStatus ? subscriptionStatus : 'Not subscribed'}</p>
      <p><strong>Credits Remaining:</strong> {credits !== null ? credits.toFixed(2) : 'Loading...'}</p>
    </SubscriptionInfo>

    <ButtonContainer>
                    <SettingsButton onClick={buyCredits}>Add Credits</SettingsButton>
                  </ButtonContainer>
    </BoxContainer>
    {/* Provide options to manage the subscription */}
   
   <BoxContainer>
    <ManageSubscription>
      <h3>Manage Your Subscription</h3>
      <ButtonContainer>
        <SettingsButton onClick={handleUpgrade}>Upgrade Plan</SettingsButton>
        {subscriptionStatus == 'active' ? <SettingsButton onClick={handleDowngrade}>Downgrade Plan</SettingsButton> : null}
        {subscriptionStatus == 'active' ? <CancelButton onClick={handleCancelPlan}>Cancel Subscription</CancelButton> : null}
      </ButtonContainer>
    </ManageSubscription>
    </BoxContainer>

    {showCancelConfirmation && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Cancel Subscription</ModalHeader>
            <ModalBody>
              <p>You will keep your credits and access to the app. Credits do not expire, so you can use them at any time. Are you sure you want to proceed?</p>
            </ModalBody>
            <ModalFooter>
              <ConfirmButton onClick={() => {
                setShowCancelConfirmation(false);
                // Redirect to Stripe Billing Portal
                window.location.href = 'https://billing.stripe.com/p/login/8wMcQj7fob0Z0Mw3cc';
              }}>
                Yes, Cancel Subscription
              </ConfirmButton>
              <SettingsButton onClick={() => setShowCancelConfirmation(false)}>
                No, Keep Subscription
              </SettingsButton>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
                
                </Section>
              )}

              {activeTab === 'earnCredits' && (
                <Section>
                  {/* Content for 'Earn Credits' tab */}



    <ReferralSection>
            <ReferralHeading>Refer a friend</ReferralHeading>
            <ReferralText>
              Share your referral link below. You'll earn <strong>5 credits</strong> each time someone subscribes using your link!
            </ReferralText>
            <ReferralLinkContainer>
              <ReferralInput
                type="text"
                value={referralLink}
                readOnly
              />
              <CopyReferralButton onClick={handleCopyReferralLink}>
                <FaCopy /> Copy Link
              </CopyReferralButton>
            </ReferralLinkContainer>
          </ReferralSection>


          <CreditsEarnedSection>
              <CreditsEarnedHeading>Credits Earned</CreditsEarnedHeading>
              <CreditsEarnedValue>{creditsEarned}</CreditsEarnedValue>
            </CreditsEarnedSection>
                  {/* Details about earning credits */}
                </Section>
              )}


            {activeTab === 'create' && credits !== null && credits < 0.15 && (
              <Notification>
                <NotificationText>
                  Generating an image requires 0.15 credits. You have {credits.toFixed(2)} credits.
                </NotificationText>
                <NotificationButton onClick={buyCredits}>
                  {getCreditsButtonText()}
                </NotificationButton>
              </Notification>
            )}
     {activeTab === 'create' && (
      <Section>

{/* <Notification>
4K image generation now available
                  </Notification> */}
        <ImageContainer>
          {generatedImages.length === 1 ? (
                     <GeneratedImageWrapper>
                     <GeneratedImageSingle src={generatedImages[0]} alt="Generated" />
                     <DownloadOverlay onClick={() => handleDownload(generatedImages[0], generationParams.prompt)}>
                       <DownloadIcon />
                     </DownloadOverlay>
                   </GeneratedImageWrapper>
          ) : generatedImages.length > 1 ? (
            <GeneratedImagesGrid>
              {generatedImages.map((imgUrl, index) => (
                <GeneratedImageWrapper key={index}>
                  <GeneratedImage src={imgUrl} alt={`Generated ${index + 1}`} />
                  <DownloadOverlay onClick={() => handleDownload(imgUrl, generationParams.prompt)}>
                    <DownloadIcon />
                  </DownloadOverlay>
                </GeneratedImageWrapper>
              ))}
            </GeneratedImagesGrid>
          ) : (
            <PlaceholderBox>
              <PlaceholderText>Your generated images will appear here</PlaceholderText>
            </PlaceholderBox>
          )}
        </ImageContainer>

        <GenerationForm onSubmit={(e) => { e.preventDefault(); generateImage(); }}>
          <GenerationInput
            type="text"
            name="prompt"
            value={generationParams.prompt}
            onChange={handleGenerationParamChange}
            placeholder="Describe your image here..."
          />
          <GenerationSelect
            name="generator"
            value={generationParams.generator}
            onChange={handleGenerationParamChange}
          >
            <option value="DEFAULT">Default Generator</option>
            {generators.map(generator => (
              <option key={generator.id} value={generator.id}>{generator.name}</option>
            ))}
          </GenerationSelect>

          <Button type="button" onClick={() => setShowAdditionalSettings(!showAdditionalSettings)}>
            {showAdditionalSettings ? 'Hide Additional Settings' : 'Show Additional Settings'}
          </Button>

          {showAdditionalSettings && (
            <>
            <CustomSizeLabel>Image size:</CustomSizeLabel>
              <GenerationSelect
                name="image_size"
                value={generationParams.image_size}
                onChange={handleGenerationParamChange}
              >
                <option value="square_hd">Square</option>
                <option value="portrait_4_3">Portrait 4:3</option>
                <option value="portrait_16_9">Portrait 16:9</option>
                <option value="landscape_4_3">Landscape 4:3</option>
                <option value="landscape_16_9">Landscape 16:9</option>
                {(credits_spent >= 4.85 || credits >= 5) ? (
                    <option value="custom">Custom size</option>
                  ) : (
                    <option value="custom" disabled>Custom size (Paid users only)</option>
                  )}
                                </GenerationSelect>
           {/* Show info message if "Custom size" is disabled */}
           {!(credits_spent >= 4.85 || credits >= 5) && (
                  <ErrorMessage>Custom image sizes, including 4K and HD resolutions, are available exclusively for paid users.</ErrorMessage>
                )}


              {generationParams.image_size === 'custom' && (
                <CustomSizeInputs>
                  <CustomSizeInputWrapper>
                    <CustomSizeLabel>Width (px):</CustomSizeLabel>
                    <CustomSizeInput
                      type="number"
                      name="width"
                      value={customSize.width}
                      onChange={handleCustomSizeChange}
                      placeholder="Width"
                      min="512"
                      max="4096"
                    />
                  </CustomSizeInputWrapper>
                  <CustomSizeInputWrapper>
                    <CustomSizeLabel>Height (px):</CustomSizeLabel>
                    <CustomSizeInput
                      type="number"
                      name="height"
                      value={customSize.height}
                      onChange={handleCustomSizeChange}
                      placeholder="Height"
                      min="512"
                      max="4096"
                    />
                  </CustomSizeInputWrapper>
                </CustomSizeInputs>
              )}

              <InputContainer>
                <CustomSizeLabel htmlFor="num_images">Number of images to generate:</CustomSizeLabel>
                <Input
                  type="number"
                  id="num_images"
                  name="num_images"
                  value={generationParams.num_images}
                  onChange={handleGenerationParamChange}
                  min="1"
                  max="4"
                />
              </InputContainer>
            </>
          )}

          <LoadingButton 
            type="submit" 
            disabled={isGenerating}
          >
            {isGenerating ? 'Generating... ' : 'Generate'}
            
            {isGenerating && <LoadingSpinner />}
          </LoadingButton>
        </GenerationForm>

        {error === 'insufficient_credits' ? (
          <InsufficientCreditsError>
            <p>Generating an image requires 0.15 credits. You have {credits.toFixed(2)} credits.</p>
            <GetCreditsButton onClick={buyCredits}>
              {getCreditsButtonText()}
            </GetCreditsButton>
          </InsufficientCreditsError>
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : null}

        {/* {showGenerationEstimate && (
          <EstimatedTime>
            Estimated time: 2-6 seconds
          </EstimatedTime>
        )} */}
      </Section>
            )}

            {activeTab === 'newGenerator' && (
              <CreateGeneratorSection>

{/* {!(credits_spent >= 4.85 || credits >= 5) && (

                <Guidance>Once your generator is created, you can generate up to 13 custom images for free. To continue creating more images beyond that, you can purchase a subscription for more generations.</Guidance>
                
)} */}
                <CreateGeneratorForm>
                  <FormBox>
                    <h3>Name</h3>
                    <InputContainer>
                      <Input
                        type="text"
                        value={newGeneratorName}
                        onChange={(e) => setNewGeneratorName(e.target.value)}
                        placeholder="My art generator"
                        ref={generatorNameInputRef}
                      />
                    </InputContainer>
                  </FormBox>
                  <FormBox>
                    <h3>Upload your Art or Photos</h3>
                    <FileInputContainer>
                      <FileInputLabel htmlFor="file-upload">
                        Choose Images

                        <LargeReactIcon />

                      </FileInputLabel>


                      <FileInput
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        multiple
                      />
                      <p>{selectedFiles.length} image{selectedFiles.length !== 1 ? 's' : ''} selected</p>
                    </FileInputContainer>



                    <ImagePreviewContainer>
                      {previewImages.map((preview, index) => (
                        <ImagePreview key={index}>
                          <PreviewImage src={preview} alt={`Preview ${index + 1}`} />
                          <DeleteIcon onClick={() => removeImage(index)} />
                        </ImagePreview>
                      ))}
                    </ImagePreviewContainer>
                    <Guidance>   
  Upload 5 to 15 high-resolution images that you want the AI to learn from for best results. The more images and the more <strong>diverse</strong> they are, the better the AI can capture the essence of your desired style.
<br></br> 
<br></br>
  Example: If you want to create variations of your own art style, upload 5 to 15 pieces of your artwork showcasing <strong>different subjects</strong> and techniques.


  {showAllExamples && (
    <>

      <ExampleText>
        Example 2: To generate photorrealistic pictures of yourself upload 5 to 15 photos of yourself, capturing <strong>different angles and expressions</strong>.
      </ExampleText>
      <ExampleText>
        Example 3: To generate art of yourself upload 5 to 15 photos of yourself including at least one art style you want.
      </ExampleText>
      <ExampleText>
        Example 4: If you have a specific image and want to generate slight variations, upload that image along with any similar ones you have.
      </ExampleText>
      <ExampleText>
        Example 5: For brand assets, upload images of your logo, products, or designs to generate consistent branding visuals.
      </ExampleText>
      <ExampleText>
        Example 6: To create AI images of a unique character, upload sketches or renders of the character in <strong>various poses and expressions</strong>.
      </ExampleText>
      <ExampleText>
        If you have a unique idea not listed here, try uploading related images and see how the AI can bring your vision to life. You can also reach out on the 'Help' page for assistance with your specific use case.
      </ExampleText>
    </>
                        )}

                        <SeeMoreButton onClick={() => setShowAllExamples(!showAllExamples)}>
                          {showAllExamples ? (
                            <>
                              Hide examples <FaChevronUp style={{ marginLeft: '5px' }} />
                            </>
                          ) : (
                            <>
                              Show more examples <FaChevronDown style={{ marginLeft: '5px' }} />
                            </>
                          )}
                        </SeeMoreButton>
                </Guidance>


                  </FormBox>
                  {warningMessage && (
                    <WarningMessage>{warningMessage}</WarningMessage>
                  )}
                  <CreateGeneratorButton 
                    onClick={createGenerator} 
                    disabled={isCreatingGenerator}
                  >
                    
                    {isCreatingGenerator ? (
                      <>
                        <LoadingSpinner />
                        Creating...
                      </>
                    ) : (
                      <>
                        Create
                      </>
                    )}
                  </CreateGeneratorButton>

                  {isCreatingGenerator && (
     <ProgressBarContainer>
       <ProgressBar progress={progress} />
     </ProgressBarContainer>
   )}
   {isCreatingGenerator && (
     <EstimatedTime>
       {Math.round(progress)}% completed ({Math.max(0, Math.ceil(135 - (progress / 100) * 135))} seconds remaining)
     </EstimatedTime>
   )}

                  <Guidance>Estimated creation time: 2 minutes</Guidance>


                {showCreditWarning && credits < 3 && (
                  <CreateGeneratorWarning>
                    Creating an AI image generator requires 3 credits. You currently have {credits.toFixed(2)} credits.<br></br>
                    <NotificationButton onClick={buyCredits} style={{ marginLeft: '10px' }}>
                      {getCreditsButtonText()}
                    </NotificationButton>
                  </CreateGeneratorWarning>
                )}
                </CreateGeneratorForm>



                {showInsufficientCreditsWarning && (
                  <InsufficientCreditsWarning>
                    <WarningText>
                      Creating a new generator requires 3 credits. You have {credits.toFixed(2)} credits.
                    </WarningText>
                    <WarningButton onClick={buyCredits}>
                      {getCreditsButtonText()}
                    </WarningButton>
                  </InsufficientCreditsWarning>
                )}
                {estimatedTime && (
                  <EstimatedTime>
                    Your custom generator is being created. It takes on average 2 minutes to create. You will be notified once it is finished.
                  </EstimatedTime>
                )}
                {successLog && (
                  <SuccessMessage>
                    Generator created successfully! You can now start generating custom images using this generator.
                    <br /> 
                    <LoadingButton onClick={() => {
                      setGenerationParams(prev => ({ ...prev, generator: newGenerator.id }));
                      setActiveTab('create');
                    }}>
                      Start generating custom images
                    </LoadingButton>
                  </SuccessMessage>
                )}
{/* 
{generators.length < 10 && (
                  <>
    <ExampleUseCase>
      {showExample ? (
        <>
          <ExampleTitle>Example Use Cases</ExampleTitle>
          <ExampleText>
            Here are some ways you can use Pictrain to create custom AI images:
          </ExampleText>
          <ExampleList>
            <ExampleListItem>
              <strong>Create Variations of Your Own Art:</strong>
              Upload 5-15 high-resolution images of your artwork to train a generator that understands your unique style. Name your generator (e.g., "My Art Style Generator"), click "Create," and wait about 2 minutes. Once ready, use prompts like "a landscape in my art style" to generate new pieces.
            </ExampleListItem>
            <ExampleListItem>
              <strong>Transform Your Photos into Art:</strong>
              Upload 5-15 diverse photos of yourself or subjects you care about. Name your generator (e.g., "My Photo Art Generator"), click "Create," and wait about 2 minutes. Use prompts like "me as a comic book character" or "my pet painted in watercolor" to generate unique artistic images.
            </ExampleListItem>
            <ExampleListItem>
              <strong>Generate Photorealistic Photos of Yourself:</strong>
              Upload 5-15 diverse photos of yourself and train a generator to create photorealistic images of you in different scenarios. Use prompts like "me in a suit" or "me in Paris" to generate unique photos of yourself in different situations.
            </ExampleListItem>
          </ExampleList>
          <ExampleText>
            These personalized generators learn from your images and can create content that reflects your style or transforms your photos into art in ways you've never imagined!
          </ExampleText>
          <ExampleText>
            You can adapt these examples to suit your own creative needs—design unique characters, generate brand assets, or explore new artistic concepts. Just upload 5-15 images related to what you want to create when setting up your generator.
          </ExampleText>
        </>
      ) : null}
      <SeeMoreButton onClick={() => setShowExample(!showExample)}>
        {showExample ? (
          <>
            Hide guide <FaChevronUp style={{ marginLeft: '5px' }} />
          </>
        ) : (
          <>
            Show guide <FaChevronDown style={{ marginLeft: '5px' }} />
          </>
        )}
      </SeeMoreButton>
    </ExampleUseCase>

                  </>
                )} */}

              </CreateGeneratorSection>

            )}

            {activeTab === 'yourGenerators' && (
              <Section>
                        <CreateCustomGeneratorButton onClick={() => setActiveTab('newGenerator')}>
                          <IoIosCreate style={{ marginRight: '8px' }} /> Create New Generator
                        </CreateCustomGeneratorButton>

                {generatorsLoaded ? (
                  <>
                    <SortContainer>
                      <SearchContainer>
                        <SearchInput
                          type="text"
                          placeholder={isMobile ? "Search" : "Search generators..."}
                          value={searchTerm}
                          onChange={handleSearchChange}
                        />
                        <SearchIcon />
                      </SearchContainer>
                      <SortButton onClick={() => toggleSort('name')}>
                        Sort by Name
                        <SortIcon />
                      </SortButton>
                    </SortContainer>
                    <Guidance>Click on a generator to begin generating images.</Guidance>

                    <GeneratorGrid>
                      <GeneratorCard onClick={() => {
                        setGenerationParams(prev => ({ ...prev, generator: "DEFAULT" }));
                        setActiveTab('create');
                      }}>
                        <GeneratorImage 
                          src={defaultGeneratorLastImage || defaultGeneratorImage} 
                          alt="Default Generator" 
                        />
                        <GeneratorName>Default Generator</GeneratorName>
                      </GeneratorCard>
                      {sortGenerators(filteredGenerators).map(generator => (
                        <GeneratorCard key={generator.id}>
                          <GeneratorImage 
                            src={generator.lastGeneratedImageUrl || `https://via.placeholder.com/150?text=${generator.name}`} 
                            alt={generator.name} 
                            onClick={() => {
                              setGenerationParams(prev => ({ ...prev, generator: generator.id }));
                              setActiveTab('create');
                            }}
                          />
                          {editingGenerator === generator.id ? (
                            <>
                              <EditNameInput
                                value={newGeneratorName}
                                onChange={(e) => setNewGeneratorName(e.target.value)}
                              />
                              <EditNameButtons>
                                <EditNameButton confirm onClick={handleSaveGeneratorName}>
                                  Save
                                </EditNameButton>
                                <EditNameButton onClick={() => setEditingGenerator(null)}>
                                  Cancel
                                </EditNameButton>

                                <DeleteButton onClick={() => handleDeleteGenerator(generator.id)}>
                            
                            <FaTrash />
                          </DeleteButton>
                              </EditNameButtons>


                            </>
                          ) : (
                            <GeneratorNameContainer>
                              <GeneratorName>{generator.name}</GeneratorName>
                              <EditButton onClick={() => handleEditGeneratorName(generator)}>
                                <FaEdit />
                              </EditButton>

                            </GeneratorNameContainer>
                          )}
                        </GeneratorCard>
                      ))}
                      {creatingGeneratorName && (
                        <LoadingGeneratorCard>
                          <GeneratorImage 
                            src={creatingGeneratorImage || loadingGeneratorImage} 
                            alt="Creating Generator" 
                          />
                          <GeneratorName>{creatingGeneratorName}</GeneratorName>
                          <LoadingText>Creating...</LoadingText>
                        </LoadingGeneratorCard>
                      )}
                    </GeneratorGrid>
                  </>
                ) : (
                  <p>Loading generators...</p>
                )}
              </Section>
            )}
 
 {activeTab === 'edit' && (
      <Section>
        {credits !== null && credits < 0.15 && (
          <Notification>
            <NotificationText>
              Editing an image requires 0.15 credits. You have {credits.toFixed(2)} credits.
            </NotificationText>
            <NotificationButton onClick={buyCredits}>
              {getCreditsButtonText()}
            </NotificationButton>
          </Notification>
        )}

{/* <Notification>
                    Edit feature that preserves your unique style, only new generated images can be edited
                  </Notification> */}


        {isGalleryVisible && (
          <>
            <h3>Select an Image to Edit</h3>
            {generationsLoaded ? (
              generations.length === 0 ? (
                <p>No generated images available. Generate some images first.</p>
              ) : (
                <GalleryGrid>
                  {generations.map((gen) => (
                    <GalleryItem key={gen.id}>
                      <GalleryImageContainer>
                        <GalleryImage
                          src={gen.image_url}
                          alt="Generated"
                          onClick={() => handleEditImage(gen)} // Change this line
                        />
                        <EditIconWrapper
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditImage(gen);
                          }}
                        >
                          <EditIcon />
                        </EditIconWrapper>
                      </GalleryImageContainer>
                      <GalleryPromptContainer>
                        <GalleryPrompt>{gen.prompt}</GalleryPrompt>
                        <CopyIconWrapper>
                          <CopyIcon onClick={() => copyToClipboard(gen.prompt)} />
                        </CopyIconWrapper>
                      </GalleryPromptContainer>
                    </GalleryItem>
                  ))}
                </GalleryGrid>
              )
            ) : (
              <p>Loading your images...</p>
            )}
      </>

)}

{!isGalleryVisible && (
  <Button onClick={() => setIsGalleryVisible(true)}>Show Gallery</Button>
)}

                  {selectedGenerationForEdit && (
                    <>
                      <h3>Edit image canvas</h3>
                      <EditorSection>
                        <EditorSubSection>
                          <ImageEditor
                            image={selectedGenerationForEdit.image_url}
                            onMaskChange={handleMaskChange}
                          />
                          <Instruction>
                            Brush over the areas you want to edit.
                          </Instruction>
                        </EditorSubSection>
                        <EditorSubSection>
                          <InputLabel>Edit instructions</InputLabel>
                          <Input
                            type="text"
                            name="prompt"
                            value={generationParams.prompt}
                            onChange={handleGenerationParamChange}
                            placeholder="E.g., Change the hat color to red..."
                          />
                          <LoadingButton onClick={generateInpaintingImage} disabled={isGenerating}>
                            {isGenerating ? (
                              <>
                                Editing...
                                <LoadingSpinner />
                              </>
                            ) : (
                              'Edit'
                            )}
                          </LoadingButton>
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </EditorSubSection>
                      </EditorSection>

                      {generatedImages.length > 0 && (
                        <>
                          <h3>Edited Image</h3>
                          <GeneratedImagesGrid>
                            {generatedImages.map((imgUrl, index) => (
                              <GeneratedImageWrapper key={index}>
                                <GeneratedImage src={imgUrl} alt={`Generated ${index + 1}`} />
                                <DownloadOverlay onClick={() => handleDownload(imgUrl, generationParams.prompt)}>
                                  <DownloadIcon />
                                </DownloadOverlay>
                              </GeneratedImageWrapper>
                            ))}
                          </GeneratedImagesGrid>
                        </>
                      )}
                    </>
                  )}
                </Section>
              )}
 {activeTab === 'generations' && (
        <Section>
          <DeleteWarning>
            Images are guaranteed to be available for at least 7 days. After that, they may be deleted at any time. We recommend that you download any images that you want to keep for longer.
          </DeleteWarning>
          {generationsLoaded ? (
            generations.length === 0 ? (
              <p>No generated images yet.</p>
            ) : (
              <>
                <GalleryGrid>
                  {generations.map((gen) => (
                    <GalleryItem key={gen.id}>
                      <GalleryImageContainer>
                        <GalleryImage
                          src={gen.image_url}
                          alt="Generated"
                          onClick={() => openModal(gen)}
                        />
                        <EditIconWrapper
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditImage(gen);
                          }}
                        >
                          <EditIcon />
                        </EditIconWrapper>
                      </GalleryImageContainer>
                      <GalleryPromptContainer>
                        <GalleryPrompt>{gen.prompt}</GalleryPrompt>
                        <CopyIconWrapper>
                          <CopyIcon onClick={() => copyToClipboard(gen.prompt)} />
                        </CopyIconWrapper>
                      </GalleryPromptContainer>
                    </GalleryItem>
                  ))}
                </GalleryGrid>
                {generations.length < totalGenerations && (
                  <LoadMoreButton onClick={loadMoreImages}>Load More</LoadMoreButton>
                )}
              </>
            )
          ) : (
            <p>Loading generations...</p>
          )}
        </Section>
      )}

      {selectedImage && (
     <Modal onClick={closeModal}>
     <ModalContent onClick={(e) => e.stopPropagation()}>
       <CloseButton onClick={closeModal}>
         <FaClose />
       </CloseButton>
 
       <ModalImage src={selectedImage.image_url} alt="Generated" />
       <ModalPrompt>{selectedImage.prompt}</ModalPrompt>
       <ModalActions>
         <ActionButton onClick={() => handleDownload(selectedImage.image_url, selectedImage.prompt)}>
           <DownloadIcon />
           Download
         </ActionButton>

         <ActionButton
                onClick={() => {
                  handleEditImage(selectedImage);
                  closeModal(); // Close the modal after clicking Edit
                }}
              >
                Edit
              </ActionButton>
         <ActionButton onClick={(e) => {
           e.stopPropagation();
           handleDeleteGeneration(selectedImage.id);
         }}>
           Delete
         </ActionButton>
       </ModalActions>
     </ModalContent>
   </Modal>
      )}



            {activeTab === 'help' && (
              <Section>
                <QuickStartGuide>
                  <h3>Quick Start Guide</h3>
                  
                  <GuideSection>
                    <GuideTitle>How to create your custom AI image generator</GuideTitle>
                    <GuideList>
                      <GuideListItem>Go to the "Home" tab and click "Create New Generator".</GuideListItem>
                      <GuideListItem>Write a name for your generator.</GuideListItem>
                      <GuideListItem>Upload 5-15 relevant images that match the style of images you want to generate.</GuideListItem>
                      <GuideListItem>Click "Create New Generator" and wait 2 minutes for your AI generator to be created. It will appear in 'Home' tab once it's ready.</GuideListItem>
                    </GuideList>
                  </GuideSection>

                  <GuideSection>
                    <GuideTitle>How to generate custom AI images</GuideTitle>
                    <GuideList>
                      <GuideListItem>Go to "Generate" page and select the generator you want to use.</GuideListItem>
                      <GuideListItem>Write a text prompt describing the image you want to generate.</GuideListItem>
                      <GuideListItem>Click "Generate" and wait a few seconds for the app to produce an image based on your prompt and generator.</GuideListItem>
                    </GuideList>
                  </GuideSection>

                  <GuideSection>
                    <GuideTitle>How to edit custom AI images</GuideTitle>
                    <GuideList>
                      <GuideListItem>Go to "Edit" page and select the image you want to edit.</GuideListItem>
                      <GuideListItem>Brush over the area you want to be edited. Write a text prompt describing the changes you want to make.</GuideListItem>
                      <GuideListItem>Click "Edit" and wait a few seconds for the app to produce an image based on your image and instructions.</GuideListItem>
                    </GuideList>
                  </GuideSection>
                  <GuideSection>
                    <GuideTitle>Tips</GuideTitle>
                    <GuideList>
                      <GuideListItem>Use high-resolution, high-quality images for best results when creating your generator</GuideListItem>
                      <GuideListItem>Use a mix of different images with varying situations and angles to create a generator that can generate a wide range of images.</GuideListItem>
                      <GuideListItem>Once you have generated some great pictures, try using them to create a new generator to further refine the results.</GuideListItem>
                      <GuideListItem>Experiment with different prompts and generators to discover new possibilities.</GuideListItem>
                    </GuideList>
                  </GuideSection>
                </QuickStartGuide>

                <FAQSection>
                  <FAQTitle>Frequently Asked Questions</FAQTitle>
                  {faqData.map((faq, index) => (
                    <FAQItem key={index}>
                      <FAQQuestion onClick={() => toggleFAQ(index)}>
                        {faq.question}
                        <FAQIcon isOpen={openFAQs[index]}>
                          <FaChevronDown />
                        </FAQIcon>
                      </FAQQuestion>
                      <FAQAnswer isOpen={openFAQs[index]}>
                        {faq.answer}
                      </FAQAnswer>
                    </FAQItem>
                  ))}
                </FAQSection>

                <h2>Any questions or feedback?</h2>

                <HelpForm 
                  onSubmit={async (formData) => {
                    setError(null);
                    try {
                      const response = await axios.post(`${API_URL}/help`, {
                        ...formData,
                        user_id: user.uid
                      });
                      if (response.data.message) {
                        setSuccessLog(response.data.message);
                      }
                    } catch (err) {
                      setError('Failed to submit help request: ' + (err.response?.data?.detail || err.message));
                    }
                  }}
                />




    <DiscordFooter>
      <DiscordLink href="https://discord.gg/NH7URS9g" target="_blank" rel="noopener noreferrer">
        <DiscordIcon />
        Join our Discord community
      </DiscordLink>
    </DiscordFooter>
              </Section>
            )}



{activeTab === 'settings' && (
  <Section>

    {/* Display current subscription and credits */}




    {/* Sign out button */}
    <SignOutContainer>
      <LogoutButton onClick={handleLogout}>Sign out</LogoutButton>
    </SignOutContainer>


          {/* Confirmation Modal */}




  </Section>



)}

</MainContent>
</ContentWrapper>
       {/* Mobile Sidebar */}
       <MobileSidebar isOpen={isMobileSidebarOpen}>

      <SidebarMenu>
        {/* <MobileSidebarHeader>        <EarlyAccessBadge>Early Access</EarlyAccessBadge></MobileSidebarHeader> */}

        <MobileSidebarButton
          active={activeTab === 'yourGenerators'}
          onClick={() => {
            handleTabClick('yourGenerators');
            closeMobileSidebar();
          }}
        >
          <FaHome style={{ marginRight: '10px' }} /> Home
        </MobileSidebarButton>
        <MobileSidebarButton
          active={activeTab === 'create'}
          onClick={() => {
            handleTabClick('create');
            closeMobileSidebar();
          }}
        >
          <RiAiGenerate style={{ marginRight: '10px' }} /> Generate
        </MobileSidebarButton>

        <MobileSidebarButton
          active={activeTab === 'edit'}
          onClick={() => {
            handleTabClick('edit');
            closeMobileSidebar();
          }}
        >
          
          <FaPaintBrush style={{ marginRight: '10px' }} /> Edit
        </MobileSidebarButton>

        <MobileSidebarButton
          active={activeTab === 'generations'}
          onClick={() => {
            handleTabClick('generations');
            closeMobileSidebar();
          }}
        >
          
          <FaImage style={{ marginRight: '10px' }} /> Gallery
        </MobileSidebarButton>
        <MobileSidebarButton
          onClick={() => {
            setActiveTab('yourPlan');
            closeMobileSidebar();
          }}
        >
          <FaGem style={{ marginRight: '10px' }} /> Your Plan
        </MobileSidebarButton>
        <MobileSidebarButton
          onClick={() => {
            setActiveTab('earnCredits');
            closeMobileSidebar();
          }}
        >


          
          <FaDollarSign style={{ marginRight: '10px' }} /> Refer a friend
        </MobileSidebarButton>


        <MobileSidebarButton
            onClick={() => {
              window.open('https://discord.gg/knkcgvzz', '_blank');
            }}
        >   
          <FaDiscord style={{ marginRight: '10px' }} /> Community
        </MobileSidebarButton>

        <MobileSidebarButton
          onClick={() => {
            setActiveTab('help');
            closeMobileSidebar();
          }}
        >
          <FaQuestionCircle style={{ marginRight: '10px' }} /> Help
        </MobileSidebarButton>
      </SidebarMenu>
      <MobileSidebarFooter>
        <MobileProfileSection>
          <MobileProfileIcon />
          <MobileProfileName>{user?.email}</MobileProfileName>
        </MobileProfileSection>
        <MobileSidebarButton onClick={handleLogout}>
          <FaSignOutAlt style={{ marginRight: '10px' }} /> Log Out
        </MobileSidebarButton>
      </MobileSidebarFooter>
    </MobileSidebar>
    <MobileSidebarOverlay isOpen={isMobileSidebarOpen} onClick={closeMobileSidebar} />

        </>

      ) : (
        <div>Loading...</div>
      )}
    </AppContainer>
  );
}

export default WebApp;
