import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import LandingPage from './LandingPage';
import Checkout from './Checkout';
import TermsOfService from './TermsOfService';
import Success from './Success';  // Import the Success component
import WebApp from './WebApp';  // Import the WebApp component
import SuccessFreeTrial from './SuccessFreeTrial';  // Import the SuccessFreeTrial component
import Art from './Art';  // Import the Art component
import Photos from './Photos';  // Import the Photos component

import Onboarding from './onboarding';

const GlobalStyle = createGlobalStyle`
  /* Your existing global styles */
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #000000;
    color: #fff;
  }

  /* Hide the reCAPTCHA badge */
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const RecaptchaContainer = styled.div`
  visibility: hidden; /* Hide the container */
`;

function App() {
  return (
    <Router>
            <GlobalStyle />

      <AppContainer>

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/success" element={<Success />} />  {/* Add this new route */}
          <Route path="/app" element={<WebApp />} />  {/* Add this new route */}
          <Route path="/successtrial" element={<SuccessFreeTrial />} />  {/* Add this new route */}
          <Route path="/art" element={<Art />} />  {/* Add this new route */}
          <Route path="/photo" element={<Photos />} />  {/* Add this new route */}
          <Route path="/onboarding" element={<Onboarding />} />  {/* Add this new route */}
        </Routes>
      </AppContainer>
    </Router>

  );
}

export default App;
