import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { RecaptchaVerifier, linkWithPhoneNumber } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import ReactCodeInput from 'react-code-input';
import { FaSpinner } from 'react-icons/fa';

// Global styles to set fonts and background
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
  body {
    font-family: 'Open Sans', sans-serif;
    background-color: #121212;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }
`;

const LoadingText = styled.p`
  color: white;
  font-size: 24px;
  margin-top: 20px;
`;

const LoadingIcon = styled(FaSpinner)`
  color: white;
  font-size: 48px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;



const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;


const Container = styled.div`
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  max-width: 300px;
  padding: 12px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #2a2a2a;
  border: 1px solid #4caf50;
  border-radius: 4px;

  &::placeholder {
    color: #b3b3b3;
  }
`;

const Button = styled.button`
  background-color: #4caf50;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 16px;

  &:hover {
    background-color: #45a049;
  }
`;

const ResendCode = styled.p`
  color: #b3b3b3;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #ffffff;
  }
`;

const Select = styled.select`
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #3a3a3a;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 97%;
  max-width: 285px;
  appearance: none;
  background-color: #1e1e1e;
  color: #ffffff;
  cursor: pointer;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #4CAF50;
  }

  // mobile
  @media (max-width: 768px) {
    width: 100%;
    max-width: 242px;
  }
`;

const Heading = styled.h1`
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
`;

const Subheading = styled.h2`
  color: #b3b3b3;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 32px;
`;

const InstructionText = styled.p`
  color: #e0e0e0;
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 32px;
`;

const MessageContainer = styled.div`
  background-color: #1e1e1e;
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 40px;
`;

const Message = styled.p`
  color: #e0e0e0;
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 24px;
`;

const VerificationHeading = styled.h3`
  color: #4caf50;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 auto 24px;
  max-width: 600px;
`;

const BulletItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  &::before {
    content: "";
    min-width: 12px;
    height: 12px;
    background-color: #4caf50;
    border-radius: 50%;
    margin-right: 16px;
    margin-top: 6px;
  }

  div {
    color: #e0e0e0;

    strong {
      color: #ffffff;
      font-weight: 600;
    }

    p {
      margin: 4px 0 0;
      font-size: 16px;
      line-height: 1.5;
    }
  }
`;

const PrivacyNote = styled.p`
  color: #b3b3b3;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  margin-top: 32px;
`;

// New styled component for the Form Container
const FormContainer = styled.div`
  background-color: #1e1e1e;
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 40px;
`;

// New Instruction Text for Verification Code Input
const VerificationInstruction = styled.p`
  color: #e0e0e0;
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 24px;
`;

// New styled component for the "Skip this step" link
const SkipLink = styled.p`
  color: #b3b3b3;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 16px;

  &:hover {
    color: #ffffff;
  }
`;

const Onboarding = () => {
  // State variables
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerificationInput, setShowVerificationInput] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'send-verification-code-button',
        { size: 'invisible' }
      );

      // Render the reCAPTCHA widget
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    }
  }, []);

  const handleSendVerificationCode = async () => {
    setIsLoading(true);
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;

    try {
      const appVerifier = window.recaptchaVerifier;

      // Trigger the reCAPTCHA verification
      await appVerifier.verify();

      // Proceed to send the verification code
      if (auth.currentUser) {
        const confirmationResult = await linkWithPhoneNumber(
          auth.currentUser,
          fullPhoneNumber,
          appVerifier
        );
        setConfirmationResult(confirmationResult);
        setShowVerificationInput(true);
      } else {
        alert('No authenticated user found. Please sign in first.');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error during linkWithPhoneNumber:', error);
      alert('Failed to send verification code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const result = await confirmationResult.confirm(verificationCode);
      // Phone number linked successfully.
      alert('Phone number verified and linked to your account.');
      navigate('/successtrial');
    } catch (error) {
      console.error('Error during confirmation:', error);
      alert('Invalid verification code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        {isLoading && (
          <LoadingOverlay>
            <LoadingIcon />
            <LoadingText>Processing...</LoadingText>
          </LoadingOverlay>
        )}
        <Heading>Welcome to Pictrain! 🎉</Heading>
        <Subheading>
          We're excited to have you join our creative community!
        </Subheading>
        <MessageContainer>
          <Message>
            This is the final step to access your free trial.
          </Message>
          <InstructionText>
            To finish setting up your account, please verify your phone number.
          </InstructionText>
          <VerificationHeading>🔒 Why Verify?</VerificationHeading>
          <BulletList>
            <BulletItem>
              <div>
                <strong>Prevent Multiple Accounts:</strong>
                <p>
                  We use phone verification to ensure that each user has only one account.
                </p>
              </div>
            </BulletItem>
            <BulletItem>
              <div>
                <strong>Maintain Fair Usage:</strong>
                <p>
                  This helps us provide a fair experience for all users during the free trial.
                </p>
              </div>
            </BulletItem>
            <BulletItem>
              <div>
                <strong>Secure Your Creations:</strong>
                <p>
                  Ensure that only you have access to your work and account.
                </p>
              </div>
            </BulletItem>
          </BulletList>
          <PrivacyNote>
            We respect your privacy. Your number will be used solely to prevent duplicate accounts and for verification purposes.
          </PrivacyNote>
        </MessageContainer>
        <FormContainer>
          {!showVerificationInput ? (
            <Form>
              <InstructionText>
                Please enter your phone number below to receive a verification code.
              </InstructionText>
              <InstructionText>
                Select your country code and enter your phone number:
              </InstructionText>
              <Select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
              >
                <option value="+1">🇺🇸 +1 United States</option>
                <option value="+355">🇦🇱 +355 Albania</option>
                <option value="+213">🇩🇿 +213 Algeria</option>
                <option value="+244">🇦🇴 +244 Angola</option>
                <option value="+54">🇦🇷 +54 Argentina</option>
                <option value="+374">🇦🇲 +374 Armenia</option>
                <option value="+61">🇦🇺 +61 Australia</option>
                <option value="+43">🇦🇹 +43 Austria</option>
                <option value="+994">🇦🇿 +994 Azerbaijan</option>
                <option value="+973">🇧🇭 +973 Bahrain</option>
                <option value="+880">🇧🇩 +880 Bangladesh</option>
                <option value="+375">🇧🇾 +375 Belarus</option>
                <option value="+32">🇧🇪 +32 Belgium</option>
                <option value="+591">🇧🇴 +591 Bolivia</option>
                <option value="+387">🇧🇦 +387 Bosnia and Herzegovina</option>
                <option value="+55">🇧🇷 +55 Brazil</option>
                <option value="+359">🇧🇬 +359 Bulgaria</option>
                <option value="+855">🇰🇭 +855 Cambodia</option>
                <option value="+1">🇨🇦 +1 Canada</option>
                <option value="+56">🇨🇱 +56 Chile</option>
                <option value="+86">🇨🇳 +86 China</option>
                <option value="+57">🇨🇴 +57 Colombia</option>
                <option value="+506">🇨🇷 +506 Costa Rica</option>
                <option value="+385">🇭🇷 +385 Croatia</option>
                <option value="+53">🇨🇺 +53 Cuba</option>
                <option value="+357">🇨🇾 +357 Cyprus</option>
                <option value="+420">🇨🇿 +420 Czech Republic</option>
                <option value="+45">🇩🇰 +45 Denmark</option>
                <option value="+1">🇩🇴 +1 Dominican Republic</option>
                <option value="+593">🇪🇨 +593 Ecuador</option>
                <option value="+20">🇪🇬 +20 Egypt</option>
                <option value="+503">🇸🇻 +503 El Salvador</option>
                <option value="+372">🇪🇪 +372 Estonia</option>
                <option value="+358">🇫🇮 +358 Finland</option>
                <option value="+33">🇫🇷 +33 France</option>
                <option value="+995">🇬🇪 +995 Georgia</option>
                <option value="+49">🇩🇪 +49 Germany</option>
                <option value="+30">🇬🇷 +30 Greece</option>
                <option value="+502">🇬🇹 +502 Guatemala</option>
                <option value="+504">🇭🇳 +504 Honduras</option>
                <option value="+852">🇭🇰 +852 Hong Kong</option>
                <option value="+36">🇭🇺 +36 Hungary</option>
                <option value="+354">🇮🇸 +354 Iceland</option>
                <option value="+91">🇮🇳 +91 India</option>
                <option value="+62">🇮🇩 +62 Indonesia</option>
                <option value="+98">🇮🇷 +98 Iran</option>
                <option value="+964">🇮🇶 +964 Iraq</option>
                <option value="+353">🇮🇪 +353 Ireland</option>
                <option value="+972">🇮🇱 +972 Israel</option>
                <option value="+39">🇮🇹 +39 Italy</option>
                <option value="+81">🇯🇵 +81 Japan</option>
                <option value="+962">🇯🇴 +962 Jordan</option>
                <option value="+7">🇰🇿 +7 Kazakhstan</option>
                <option value="+254">🇰🇪 +254 Kenya</option>
                <option value="+82">🇰🇷 +82 South Korea</option>
                <option value="+965">🇰🇼 +965 Kuwait</option>
                <option value="+856">🇱🇦 +856 Laos</option>
                <option value="+371">🇱🇻 +371 Latvia</option>
                <option value="+961">🇱🇧 +961 Lebanon</option>
                <option value="+370">🇱🇹 +370 Lithuania</option>
                <option value="+352">🇱🇺 +352 Luxembourg</option>
                <option value="+60">🇲🇾 +60 Malaysia</option>
                <option value="+356">🇲🇹 +356 Malta</option>
                <option value="+52">🇲🇽 +52 Mexico</option>
                <option value="+373">🇲🇩 +373 Moldova</option>
                <option value="+976">🇲🇳 +976 Mongolia</option>
                <option value="+212">🇲🇦 +212 Morocco</option>
                <option value="+31">🇳🇱 +31 Netherlands</option>
                <option value="+64">🇳🇿 +64 New Zealand</option>
                <option value="+505">🇳🇮 +505 Nicaragua</option>
                <option value="+234">🇳🇬 +234 Nigeria</option>
                <option value="+47">🇳🇴 +47 Norway</option>
                <option value="+968">🇴🇲 +968 Oman</option>
                <option value="+92">🇵🇰 +92 Pakistan</option>
                <option value="+970">🇵🇸 +970 Palestine</option>
                <option value="+507">🇵🇦 +507 Panama</option>
                <option value="+595">🇵🇾 +595 Paraguay</option>
                <option value="+51">🇵🇪 +51 Peru</option>
                <option value="+63">🇵🇭 +63 Philippines</option>
                <option value="+48">🇵🇱 +48 Poland</option>
                <option value="+351">🇵🇹 +351 Portugal</option>
                <option value="+974">🇶🇦 +974 Qatar</option>
                <option value="+40">🇷🇴 +40 Romania</option>
                <option value="+7">🇷🇺 +7 Russia</option>
                <option value="+966">🇸🇦 +966 Saudi Arabia</option>
                <option value="+381">🇷🇸 +381 Serbia</option>
                <option value="+65">🇸🇬 +65 Singapore</option>
                <option value="+421">🇸🇰 +421 Slovakia</option>
                <option value="+386">🇸🇮 +386 Slovenia</option>
                <option value="+27">🇿🇦 +27 South Africa</option>
                <option value="+34">🇪🇸 +34 Spain</option>
                <option value="+94">🇱🇰 +94 Sri Lanka</option>
                <option value="+46">🇸🇪 +46 Sweden</option>
                <option value="+41">🇨🇭 +41 Switzerland</option>
                <option value="+886">🇹🇼 +886 Taiwan</option>
                <option value="+66">🇹🇭 +66 Thailand</option>
                <option value="+216">🇹🇳 +216 Tunisia</option>
                <option value="+90">🇹🇷 +90 Turkey</option>
                <option value="+380">🇺🇦 +380 Ukraine</option>
                <option value="+971">🇦🇪 +971 United Arab Emirates</option>
                <option value="+44">🇬🇧 +44 United Kingdom</option>
                <option value="+598">🇺🇾 +598 Uruguay</option>
                <option value="+998">🇺🇿 +998 Uzbekistan</option>
                <option value="+58">🇻🇪 +58 Venezuela</option>
                <option value="+84">🇻🇳 +84 Vietnam</option>
                <option value="+967">🇾🇪 +967 Yemen</option>
                <option value="+260">🇿🇲 +260 Zambia</option>
                <option value="+263">🇿🇼 +263 Zimbabwe</option>
              </Select>
              <Input
                type="tel"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <Button
                id="send-verification-code-button"
                type="button"
                onClick={handleSendVerificationCode}
              >
                Send Verification Code
              </Button>
              <SkipLink onClick={() => navigate('/checkout')}>
                Go back
              </SkipLink>
            </Form>
          ) : (
            <Form onSubmit={handleVerifyCode}>
              <VerificationInstruction>
                Please enter the verification code you received.
              </VerificationInstruction>
              <Input
                type="text"
                placeholder="Enter verification code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <Button type="submit">Verify Code</Button>
              <ResendCode onClick={handleSendVerificationCode}>
                Resend Code
              </ResendCode>
              <SkipLink onClick={() => navigate('/checkout')}>
                Skip this step
              </SkipLink>
            </Form>
          )}
        </FormContainer>
      </Container>
    </>
  );
};

export default Onboarding;