import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #1a1a1a;
  color: #fff;
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
`;


const Message = styled.p`
  font-size: 1.2em;
  margin-bottom: 30px;
  text-align: center;
  max-width: 800px;

  ol {
    display: inline-block;
    text-align: left;
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
`;


const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;

const Success = () => {
  const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleGoToApp = () => {
    // Replace this with the actual URL of your app
    navigate('/app');
  };

  return (
    <Container>
      <Confetti
        width={windowDimensions.width}
        height={windowDimensions.height}
        recycle={false}
        numberOfPieces={200}
      />
    <Title>🚀 Let’s Get Started!</Title>
    <Message>
    Your free trial comes with 5 credits. Begin by creating your custom AI image generator:
    <ol>
        <li>Upload 5 to 15 of your favorite pictures.</li>
        <li>Start creating amazing custom images!</li>
      </ol>
      </Message>
      <Button onClick={handleGoToApp}>Start uploading</Button>
    </Container>
  );
};

export default Success;
