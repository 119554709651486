import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { FaGoogle, FaUsers, FaImage, FaUser, FaBuilding, FaPalette, FaUserFriends, FaCat, FaTwitter, FaSpinner } from 'react-icons/fa';
import { auth } from './firebase';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  onAuthStateChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut
} from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import YouTube from 'react-youtube';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #000;
    color: #fff;
    overflow-x: hidden; // Prevent horizontal scrolling
  }

  * {
    box-sizing: inherit;
  }
`;

const PageWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const Hero = styled.section`
  background: url('path-to-your-image.jpg') no-repeat center center/cover;
  background-color: rgba(0, 0, 0, 0.5); /* Fallback color */
  background-blend-mode: darken;
  width: 100%;
  padding: 100px 20px;
  color: #fff; /* Light text color for better contrast */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #111; /* Dark background for hero */

  @media (max-width: 768px) {
    padding: 60px 20px;
  }
`;

const HeroTitle = styled.h1`
  font-size: 4em;
  margin: 0;
  font-weight: bold;
  margin-left: 50px;
  margin-right: 50px;

  @media (max-width: 768px) {
    font-size: 2.5em;
    margin-top: 20px;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 2em; /* Increased font size */
  margin: 20px 0;
  font-weight: bold; /* Added bold font weight */
  margin-left: 50px;
  margin-right: 50px;

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 18px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #45a049;
  }
`;

const EmailInput = styled.input`
  padding: 15px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  width: 300px;
  margin-left: 10px;
  max-width: 100%;

`;

const SignUpButton = styled(Button)`
  padding: 15px 30px;
  background-color: #ff5722;
  &:hover {
    background-color: #e64a19;
  }
`;

const HeroForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  max-width: 300px; // Reduced from 400px
  background-color: rgba(255, 255, 255, 0.1); // Semi-transparent background
  padding: 30px 40px; // 30px for top and bottom, 40px for left and right
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 20px;
  }
`;

const HeroEmailInput = styled(EmailInput)`
  margin-bottom: 15px;
  width: 100%;
  max-width: 275px; // Increased from 240px
  background-color: rgba(255, 255, 255, 0.9); // More opaque background for input
  color: #000; // Dark text for better contrast
  text-align: center; // Center the text inside the input

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const HeroSignUpButton = styled(SignUpButton)`
  width: 100%;
  max-width: 260px; // Match the new max-width of HeroEmailInput
  margin-bottom: 15px;
  padding: 10px 20px;
  font-size: 16px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const GoogleSignInButton = styled(Button)`
  background-color: #4285F4;
  width: 100%;
  max-width: 260px;
  padding: 10px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #357ae8;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const GoogleIcon = styled(FaGoogle)`
  margin-right: 10px;
`;

const Header = styled.header`
  background-color: transparent;
  color: white;
  padding: 20px 0;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
`;

const Logo = styled.img`
  height: 50px;
`;

const SubTitle = styled.h2`
  color: #fff; /* Changed font color to white */
  font-size: 2em;
  margin: 20px 0;
`;

const Content = styled.div`
  margin: 40px 0;
  padding: 20px;
  background: #111; /* Dark background for content */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  color: #fff; /* Light text color for better contrast */
`;

const FloatingSignUp = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(17, 17, 17, 0.9);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const EmailForm = styled.div`
  display: flex;
  align-items: center;
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
`;

const Footer2 = styled.footer`
  background-color: #111;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const TwitterLink = styled.a`
  color: #1DA1F2;
  font-size: 24px;
  transition: color 0.3s ease;
  &:hover {
    color: #0c85d0;
  }
`;

const DashboardButton = styled.button`
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
  width: 100%;
  max-width: 260px;
  &:hover {
    background-color: #45a049;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const LoadingIcon = styled(FaSpinner)`
  color: white;
  font-size: 48px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingText = styled.p`
  color: white;
  font-size: 24px;
  margin-top: 20px;
`;

const HeaderButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const LogoutButton = styled.button`
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d32f2f;
  }
`;

const UseCaseDescription = styled.p`
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #ddd;
  line-height: 1.6;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;


const InfoBanner = styled.div`
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  margin-top: 10px;
  border-radius: 5px;
`;

const GuideWorkflow = styled.div`
  margin-top: 60px;
  text-align: center;
  background-color: #1a1a1a;
  padding: 40px 20px; // Added horizontal padding
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const WorkflowTitle = styled.h3`
  font-size: 32px;
  margin-bottom: 40px;
  color: white;
  letter-spacing: 2px;
`;

const WorkflowSteps = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
`;

const WorkflowStep = styled.div`
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  background-color: #222;
  padding: 30px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px; // Added margin-bottom for better spacing on mobile

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.3);
  }
`;

const StepNumber = styled.span`
  font-size: 48px;
  font-weight: bold;
  color: white;
  display: block;
  margin-bottom: 15px;
`;

const StepText = styled.span`
  font-size: 18px;
  line-height: 1.5;
  color: #ddd;
`;

const SocialProof = styled.div`
  background-color: #1a1a1a;
  padding: 40px 20px; // Added horizontal padding
  border-radius: 15px;
  margin-top: 60px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const SocialProofTitle = styled.h3`
  font-size: 32px;
  color: #fff;
  margin-bottom: 30px;
`;

const SocialProofGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap; // Allow items to wrap on smaller screens
`;

const SocialProofItem = styled.div`
  background-color: #222;
  padding: 30px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  flex: 0 1 250px;
  margin-bottom: 20px; // Added margin-bottom for better spacing on mobile

  &:hover {
    transform: translateY(-5px);
  }
`;

const SocialProofIcon = styled.div`
  font-size: 48px;
  color: #4CAF50;
  margin-bottom: 15px;
`;

const SocialProofNumber = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
`;

const SocialProofText = styled.p`
  font-size: 18px;
  color: #ddd;
`;

const UseCaseSection = styled.div`
  background-color: #1a1a1a;
  padding: 60px 20px;
  border-radius: 15px;
  margin-top: 60px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const UseCaseTitle = styled.h3`
  font-size: 32px;
  color: #fff;
  margin-bottom: 30px;
`;

const UseCaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
`;

const UseCaseItem = styled.div`
  background-color: #222;
  padding: 30px;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.3);
  }

  ${props => props.selected && `
    background-color: #4CAF50;
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.5);

    ${UseCaseIcon} {
      color: #fff;
    }

    ${UseCaseText} {
      color: #fff;
    }
  `}
`;

const UseCaseIcon = styled.div`
  font-size: 48px;
  color: #4CAF50;
  margin-bottom: 15px;
`;

const UseCaseText = styled.p`
  font-size: 18px;
  color: #ddd;
`;

const VideoGuidance = styled.h3`
  font-size: 24px;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const YouTubeContainer = styled.div`
  position: relative;
  padding-bottom: ${props => props.isMobile ? '177.77%' : '56.25%'}; // 9:16 for mobile, 16:9 for desktop
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
`;

const StyledYouTube = styled(YouTube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const MobileNotification = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 1000;
  max-width: 80%;
`;

const LandingPage = () => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [selectedUseCase, setSelectedUseCase] = useState('Generate custom art of your favorite characters with the exact same art style as your images');

  const useCases = [
    { icon: <FaUser />, text: "Generate art and pictures of yourself with 100% resemblance in different styles and settings" },
    { icon: <FaUserFriends />, text: "Generate art and pictures of your family and friends with 100% resemblance in different styles and settings" },
    { icon: <FaPalette />, text: "Generate custom art of your favorite characters with the exact same art style as your images" },
    { icon: <FaCat />, text: "Generate pictures and art of your pet with 100% resemblance in different styles and settings" },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [showMobileNotification, setShowMobileNotification] = useState(false);

  const [isGoogleSignInLoading, setIsGoogleSignInLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const useCaseImages = {
    "Generate art and pictures of yourself with 100% resemblance in different styles and settings": "",
    "Generate art and pictures of your family and friends with 100% resemblance in different styles and settings": "",
    "Generate custom art of your favorite characters with the exact same art style as your images": isMobile ? "/show2mobile.png" : "/show2.png",
    "Generate pictures and art of your pet with 100% resemblance in different styles and settings": isMobile ? "/show4mobile.png" : "/show4.png",
  };

  const useCaseVideos = {
    "Generate art and pictures of yourself with 100% resemblance in different styles and settings": "7aCWk6yVrtM",
    "Generate art and pictures of your family and friends with 100% resemblance in different styles and settings": "7aCWk6yVrtM",
    "Generate custom art of your favorite characters with the exact same art style as your images": null,
    "Generate pictures and art of your pet with 100% resemblance in different styles and settings": null,
  };

  const handleUseCaseClick = (text) => {
    setSelectedUseCase(text);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      console.log("Auth state changed. Current user:", currentUser);
      setUser(currentUser);
      if (currentUser) {
        console.log("User is signed in. Fetching user data from Firestore...");
        const db = getFirestore();
        const userDocRef = doc(db, 'customers', currentUser.uid);
        
        try {
          let attempts = 0;
          const maxAttempts = 10;
          const checkDocument = async () => {
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              console.log("User data fetched:", userData);
              if ((userData.credits === undefined && userData.credits_spent === undefined) || (userData.credits === 0 && userData.credits_spent === 0)) {
                console.log("Credits and credits_spent are undefined or zero. Redirecting to checkout...");
                navigate('/app');
              } else {
                console.log("Credits or credits_spent are defined:", userData.credits, userData.credits_spent);
                navigate('/app');
              }
            } else {
              console.log("User document does not exist in Firestore. Attempt:", attempts + 1);
              if (attempts < maxAttempts) {
                attempts++;
                setTimeout(checkDocument, 1000); // Wait 1 second before trying again
              } else {
                console.log("Max attempts reached. User document not found.");
                navigate('/app'); // Redirect to checkout as a fallback
              }
            }
          };
          
          checkDocument();
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        console.log("No user is signed in.");
      }
    });

    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      setIsLoading(true); // Start loading
      signInWithEmailLink(auth, email, window.location.href)
      .then(async (result) => {
        window.localStorage.removeItem('emailForSignIn');
        // Fetch user data from Firestore
        const db = getFirestore();
        const userDocRef = doc(db, 'customers', result.user.uid);
        
        try {
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setTimeout(() => {
              setIsLoading(false);
              if ((userData.credits === undefined && userData.credits_spent === undefined) || 
                  (userData.credits === 0 && userData.credits_spent === 0)) {
                navigate('/app');
              } else {
                navigate('/app');
              }
            }, 7000);
          } else {
            console.log("User document does not exist in Firestore.");
            setIsLoading(false);
            // You might want to handle this case, perhaps by creating a new document or showing an error
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setIsLoading(false);
          // Handle error (e.g., show error message to user)
        }
      })
      .catch((error) => {
        console.error("Error signing in with email link:", error);
        setIsLoading(false);
        // Handle error (e.g., show error message to user)
      });
    }

    return () => unsubscribe();
  }, [navigate]);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setIsGoogleSignInLoading(true);
      const result = await signInWithPopup(auth, provider);
      console.log("Signed in user:", result.user);
  
      // Fetch user data from Firestore
      const db = getFirestore();
      const userDocRef = doc(db, 'customers', result.user.uid);
      
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        if ((userData.credits === undefined && userData.credits_spent === undefined) || 
            (userData.credits === 0 && userData.credits_spent === 0)) {
          navigate('/app');
        } else {
          navigate('/app');
        }
      } else {
        console.log("User document does not exist in Firestore.");
        navigate('/app'); // Redirect new users to checkout
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
      // Display error message to the user
      alert(`Error signing in with Google: ${error.message}`);
    } finally {
      setIsGoogleSignInLoading(false);
    }
  };

  const goToDashboard = () => {
    console.log("Attempting to navigate to dashboard");
    navigate('/app');
  };

  useEffect(() => {
    // console.log("Current user state:", user);
  }, [user]);

  const sendSignInLink = async (e) => {
    e.preventDefault();
    const actionCodeSettings = {
      url: window.location.href, // Use the current URL
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      if (window.innerWidth <= 768) {
        setShowMobileNotification(true);
        setTimeout(() => setShowMobileNotification(false), 7000); // Hide after 5 seconds
      } else {
        alert('Check your inbox and sign in via the link in the email to start creating AI custom images!');
      }
    } catch (error) {
      console.error("Error sending sign-in link:", error);
      alert('Error sending sign-in link. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
      // You can add additional logic here, such as showing a message to the user
    } catch (error) {
      console.error("Error signing out:", error);
      // Handle errors here, such as displaying an error message to the user
    }
  };

  const ProblemImage = styled(ResponsiveImage)`
    margin-top: 40px;
    margin-bottom: 20px;
  `;

  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        {(isLoading || isGoogleSignInLoading) && (
          <LoadingOverlay>
            <LoadingIcon />
            <LoadingText>
              {isLoading ? "Setting up your account..." : "Signing in with Google..."}
            </LoadingText>
          </LoadingOverlay>
        )}
        <MainContent>
          <Header>
            <Logo src="/logo.png" alt="PicTrain Logo" />
            <HeaderButtons>
              {user ? (
                <>
                  <DashboardButton onClick={goToDashboard}>Go to App</DashboardButton>
                  <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
                </>
              ) : (
                <GoogleSignInButton onClick={signInWithGoogle}>
                  <GoogleIcon /> Sign in with Google
                </GoogleSignInButton>
              )}
            </HeaderButtons>
          </Header>
          <Hero>
            <HeroTitle>Generate AI custom images tailored to your pictures</HeroTitle>
            <HeroSubtitle>Generate high quality images with 100% resemblance to your pictures</HeroSubtitle> 
            <InfoBanner>Simple and easy to use, no technical skills required</InfoBanner>
            <HeroForm>
              <HeroEmailInput 
                type="email" 
                placeholder="Enter your email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <HeroSignUpButton onClick={sendSignInLink}>Start Creating</HeroSignUpButton>
              {!user ? (
                <GoogleSignInButton onClick={signInWithGoogle}>
                  <GoogleIcon /> Continue with Google
                </GoogleSignInButton>
              ) : (
                <DashboardButton onClick={goToDashboard}>
                  Go to App
                </DashboardButton>
              )}
            </HeroForm>
          </Hero>
          
          <Container>
            <Content>
              <SubTitle>Tired of generic results from AI image generators that fail to meet your specific requirements and pictures?</SubTitle>
              <ProblemImage 
                src={isMobile ? "/problemmobile.png" : "/problem.png"} 
                alt="Problem illustration" 
              />
            </Content>
            <Content>
            <SubTitle>With Pictrain, you can generate high-quality, custom, and consistent images tailored to your specific pictures and needs in just a few seconds</SubTitle>
            {useCaseImages[selectedUseCase] && (
      <ResponsiveImage 
        src={useCaseImages[selectedUseCase]} 
        alt={`Illustration for ${selectedUseCase}`} 
      />
    )}
    {useCaseVideos[selectedUseCase] && (
      <>
        <VideoGuidance>{selectedUseCase} video demonstration</VideoGuidance>
        <YouTubeContainer isMobile={isMobile}>
          <StyledYouTube
            videoId={useCaseVideos[selectedUseCase]}
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                autoplay: 0,
                rel: 0,  // This prevents showing related videos
                modestbranding: 1,  // This removes most YouTube branding
                controls: 1,
                ...(isMobile ? { orientation: 'portrait' } : {}),
              },
            }}
          />
        </YouTubeContainer>
      </>
    )}
              
              <UseCaseSection>
                <UseCaseTitle>Explore popular use cases</UseCaseTitle>
                <UseCaseGrid>
                  {useCases.map((useCase, index) => (
                    <UseCaseItem 
                      key={index} 
                      onClick={() => handleUseCaseClick(useCase.text)}
                      selected={selectedUseCase === useCase.text}
                    >
                      <UseCaseIcon>{useCase.icon}</UseCaseIcon>
                      <UseCaseText>{useCase.text}</UseCaseText>
                    </UseCaseItem>
                  ))}
                </UseCaseGrid>
              </UseCaseSection>
              

              {selectedUseCase && (useCaseImages[selectedUseCase] || useCaseVideos[selectedUseCase]) && (
  <>

    <InfoBanner>
    Works with 2D/3D art styles, 2D/3D characters/objects/assets, photorrealistic pictures, custom brand assets, humans, animals, monsters or anything else you might have custom images for
    </InfoBanner>
  </>
)}

              <GuideWorkflow>
                <WorkflowTitle>Quick steps to AI Custom Images</WorkflowTitle>
                <WorkflowSteps>
                  <WorkflowStep>
                    <StepNumber>1</StepNumber>
                    <StepText>Upload 5-15 images you want the custom images to be based on</StepText>
                  </WorkflowStep>
                  <WorkflowStep>
                    <StepNumber>2</StepNumber>
                    <StepText>Wait 2 minutes while your AI custom image generator is created</StepText>
                  </WorkflowStep>
                  <WorkflowStep>
                    <StepNumber>3</StepNumber>
                    <StepText>Generate your custom images</StepText>
                  </WorkflowStep>
                </WorkflowSteps>
              </GuideWorkflow>
              <SocialProof>
                <SocialProofTitle>Join our growing community</SocialProofTitle>
                <SocialProofGrid>
                  <SocialProofItem>
                    <SocialProofIcon><FaUsers /></SocialProofIcon>
                    <SocialProofNumber>1,000+</SocialProofNumber>
                    <SocialProofText>Active users already creating their own AI custom images</SocialProofText>
                  </SocialProofItem>
                  <SocialProofItem>
                    <SocialProofIcon><FaImage /></SocialProofIcon>
                    <SocialProofNumber>20,000+</SocialProofNumber>
                    <SocialProofText>AI custom images generated</SocialProofText>
                  </SocialProofItem>
                </SocialProofGrid>
              </SocialProof>

  

              <Footer2>
                <FooterLink href="/terms-of-service">ToS and Privacy Policy</FooterLink>
                <br></br> <br></br><br></br>
                {/* <TwitterLink href="https://x.com/PictrainAI" target="_blank" rel="noopener noreferrer">
                  <FaTwitter />
                </TwitterLink>
                */}
              </Footer2>
            </Content>
          </Container>
        </MainContent>
        
        {showMobileNotification && (
          <MobileNotification>
            Check your inbox and sign in with the link in the email to start creating AI custom images!
          </MobileNotification>
        )}
        
      </PageWrapper>
    </>
  );
};

export default LandingPage;