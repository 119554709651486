import React, { useRef, useState, useEffect, useCallback } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import styled from 'styled-components';

const EditorContainer = styled.div`
position: relative;
width: 100%;
margin-top: 10px;
max-width: 800px; /* Limit the container's maximum width */
margin-left: auto;
margin-right: auto; /* Center the container horizontally */
`;

const CanvasWrapper = styled.div`
  position: relative;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledCanvas = styled(ReactSketchCanvas)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
`;

const ActionButton = styled.button`
  background-color: #333333;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #444444;
  }
`;

const BrushSizeContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BrushSizeLabel = styled.label`
  color: #ffffff;
`;

const BrushSizeSlider = styled.input`
  width: 200px;
  /* Additional styling as needed */
`;

const ImageEditor = ({ image, onMaskChange }) => {
  const canvasRef = useRef();
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef();
  const [brushSize, setBrushSize] = useState(20); // Default brush size is 20

  useEffect(() => {
    // Load the image to get its dimensions
    const img = new Image();
    img.src = image;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  }, [image]);

  useEffect(() => {
    // Update the container width when resized
    const handleResize = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setContainerWidth(rect.width);
      }
    };

    handleResize(); // Initial size
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSaveMask = async () => {
    try {
      const dataUrl = await canvasRef.current.exportImage('png');

      const img = new Image();
      img.src = dataUrl;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');

        // Draw the exported image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Get the image data
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        // Process the image data to create the mask
        for (let i = 0; i < data.length; i += 4) {
          const alpha = data[i + 3];

          if (alpha > 0) {
            // Set pixel to white
            data[i] = 255;
            data[i + 1] = 255;
            data[i + 2] = 255;
            data[i + 3] = 255;
          } else {
            // Set pixel to black
            data[i] = 0;
            data[i + 1] = 0;
            data[i + 2] = 0;
            data[i + 3] = 255; // Ensure full opacity for black pixels
          }
        }

        // Put the image data back to the canvas
        ctx.putImageData(imageData, 0, 0);

        // Export the canvas as a Data URL
        const maskDataUrl = canvas.toDataURL('image/png');

        // Convert Data URL to Blob
        const response = await fetch(maskDataUrl);
        const blob = await response.blob();

        // Pass the Data URL and Blob back to the parent component
        onMaskChange(maskDataUrl, blob);
      };
    } catch (error) {
      console.error('Error exporting mask image:', error);
    }
  };

  // Debounce function
  const debounce = (func, wait = 500) => {
    let timeout;
    return (...args) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
        timeout = null;
      }, wait);
    };
  };

  // Debounced handleSaveMask
  const debouncedHandleSaveMask = useCallback(debounce(handleSaveMask, 500), [handleSaveMask]);

  const handleResetCanvas = () => {
    if (canvasRef.current) {
      canvasRef.current.clearCanvas();
    }
  };

  // Calculate the aspect ratio
  const aspectRatio =
    imageDimensions.width && imageDimensions.height
      ? imageDimensions.height / imageDimensions.width
      : 1;

  // Set the editor width and calculate height
  const editorWidth = containerWidth;
  const editorHeight = editorWidth * aspectRatio;

  return (
    <>
      <EditorContainer ref={containerRef}>
        <CanvasWrapper width={editorWidth} height={editorHeight}>
          <StyledImage src={image} alt="Uploaded" />
          <StyledCanvas
            ref={canvasRef}
            strokeWidth={parseInt(brushSize)}
            strokeColor="black"
            canvasColor="transparent"
            onChange={debouncedHandleSaveMask} // Trigger autosave on canvas change
          />
        </CanvasWrapper>
      </EditorContainer>

      <BrushSizeContainer>
        <BrushSizeLabel htmlFor="brushSize">Brush size:</BrushSizeLabel>
        <BrushSizeSlider
          id="brushSize"
          type="range"
          min="1"
          max="100"
          value={brushSize}
          onChange={(e) => setBrushSize(e.target.value)}
        />
        <BrushSizeLabel>{brushSize}</BrushSizeLabel>
      </BrushSizeContainer>

      <ButtonContainer>
        {/* <ActionButton onClick={handleSaveMask}>Save Area</ActionButton> */}
        <ActionButton onClick={handleResetCanvas}>Reset Area</ActionButton>
      </ButtonContainer>
    </>
  );
};

export default ImageEditor;