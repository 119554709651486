import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #1a1a1a;
  color: #fff;
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
`;

const Message = styled.p`
  font-size: 1.2em;
  margin-bottom: 30px;
  text-align: center;
  max-width: 600px;
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;

const Success = () => {
  const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleGoToApp = () => {
    // Replace this with the actual URL of your app
    navigate('/app');
  };

  return (
    <Container>
      <Confetti
        width={windowDimensions.width}
        height={windowDimensions.height}
        recycle={false}
        numberOfPieces={200}
      />
      <Title>Payment successful!</Title>
      <Message>
        Thank you for your purchase. Your credits have been added to your account, and you can now start using them in the WebApp.

        Begin by creating your custom AI image generator:
    <ol>
        <li>Upload 5 to 15 of your favorite pictures related to your use case. Make sure they are high-resolution and varied.</li>
        <li>Our AI Image model is trained on your images, you get your own custom AI image generator in 2 minutes</li>        
        <li>Generate the custom AI images you want using simple text prompts</li>

      </ol>
      </Message>
      <Button onClick={handleGoToApp}>Create custom AI image generator</Button>
    </Container>
  );
};

export default Success;
