import React, { useState } from 'react';
import styled from 'styled-components';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
  margin: 0 auto;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #333;
  background-color: #222;
  color: #ddd;

  &::placeholder {
    color: #888;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px;
  border: 1px solid #333;
  background-color: #222;
  color: #ddd;
  resize: vertical;

  &::placeholder {
    color: #888;
  }
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #444;
  color: #ddd;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;

function HelpForm({ onSubmit }) {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Your Name"
        required
      />
      <Input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Your Email"
        required
      />
      <TextArea
        name="message"
        value={formData.message}
        onChange={handleChange}
        placeholder="How can we help you?"
        required
      />
      <Button type="submit">Submit</Button>
    </FormContainer>
  );
}

export default HelpForm;
